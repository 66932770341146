import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown, message, Select, Modal } from "antd";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useViewport } from "../hooks/useViewport";

const LOCALES_LIST = [
    {
      label: "English",
      value: "en-US",
    },
    {
      label: "简体中文",
      value: "zh-CN",
    },
    {
      label: "繁體中文",
      value: "zh-TW"
    }
];

export default function AppHeader(params) {

    const navigate = useNavigate()
    const location = useLocation();
    const { t } = useTranslation()
    const {width} = useViewport()

    const cacheLang = localStorage.getItem('btl.lang');
    const statementOpen = localStorage.getItem('agreeStatement');
    const structuredStatementOpen = localStorage.getItem('agreeStructuredStatement')

    let [anchor,setAnchor] = useState(sessionStorage.getItem('anchorPoint'))
    let [isAbout, setIsAbout] = useState(false);
    let [lang, setLang] = useState(cacheLang ? cacheLang : navigator.language === 'zh-CN' ? 'zh-CN' : navigator.language === 'zh-TW' ? 'zh-TW' : navigator.language === 'zh-HK' ? 'zh-TW' : 'en-US');
    let [headerClass,setHeaderClass] = useState()
    let [userInfo,setUserInfo] = useState({})
    let [mobileMenuOpen,setMobileMenuOpen] = useState({
        'about': false,
        'service': false,
        'insights': false
    })
    let [showMune,setShowMenu] = useState(false);
    let [aboutOpen,setAboutOpen] = useState(false);
    let [servicesOpen,setServicesOpen] = useState(false);
    let [insightsOpen,setInsightsOpen] = useState(false);
    let [loginOpen,setLoginOpen] = useState(false);
    let [AMCOpen,setAMCOpen] = useState(false)
    let [checkDrop,setCheckDrop] = useState()
    let [statementModal,setStatementModal] = useState(!statementOpen ? true : false)
    let [structuredStatementModal,setStructuredStatementModal] = useState(!structuredStatementOpen && statementOpen && location.pathname.indexOf('AMC') > -1 ? true : false)
    let [modalLang,setModalLang] = useState(cacheLang ? cacheLang : navigator.language)


    const LIST = [
        // {
        //     label: t("header.title4"),
        //     href: "/login"
        // }
    ]


    const items = [
        {
          key: '1',
          label: (
            <a onClick={()=>linkClick("about","introduction")}>
              {t("header.title1-sub1")}
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a onClick={()=>linkClick("about","values")}>
              {t("header.title1-sub2")}
            </a>
          ),
        },
        {
          key: '3',
          label: (
            <a onClick={()=>linkClick("about","team")}>
              {t("header.title1-sub3")}
            </a>
          ),
        },
        {
          key: '5',
          label: (
            <a onClick={()=>linkClick("about","investment")}>
              {t("header.title1-sub5")}
            </a>
          )
        },
        {
          key: '4',
          label: (
            <a onClick={()=>linkClick("about","news")}>
                {t("header.title1-sub4")}
            </a>
          ),
        },
      ];

    const serviceItems = [
        {
          key: '5',
          label: (
            <a onClick={()=>linkClick("services","wealth")}>
              {t("index.services-subtitle5")}
            </a>
          ),
        },
        {
          key: '1',
          label: (
            <a onClick={()=>linkClick("services","Asset_Management")}>
              {t("header.title2-sub1")}
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a onClick={()=>linkClick("services","Investment_Management")}>
              {t("header.title2-sub2")}
            </a>
          ),
        },
        {
          key: '4',
          label: (
            <a onClick={()=>linkClick("services","Brokerage_Business")}>
              {t("header.title2-sub4")}
            </a>
          ),
        }
    ]

    const insightsItems = [
      {
        key: '1',
        label: (
          <a onClick={()=>linkClick("insights","Special_report")}>
            {t("header.title3-sub1")}
          </a>
        ),
      },
      
      {
        key: '3',
        label: (
          <a onClick={()=>linkClick("insights","Market_review")}>
            {t("header.title3-sub3")}
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <a onClick={()=>linkClick("insights","Research_report")}>
            {t("header.title3-sub2")}
          </a>
        ),
      },
  ]

    const Structured_ProductsItems = [
      {
        key: '1',
        label: (
          <a onClick={()=>linkClick("AMC","TrackingNotes")}>
            {t("AMCPage.subtitle2")}
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <a onClick={()=>linkClick("AMC","TrackingCertificate")}>
            {t("AMCPage.subtitle1")}
          </a>
        ),
      },
      {
        key: '3',
        label: (
          <a onClick={()=>linkClick("AMC","ProductRisk")}>
            {t("structuredProductRisk.title")}
          </a>
        )
      }
    ]

    const logoutItem = [
        {
          key: '1',
          label: (
            <span onClick={()=>logout()}>{t("header.title4-sub1")}</span>
          )
        }
    ]

    const modalLangList = [
      {
          value: 'en-US',
          label: (
              <div onClick={()=>modalLangChange('en-US')}>English</div>
          )
      },
      {
          value: 'zh-TW',
          label: (
              <div onClick={()=>modalLangChange('zh-TW')}>繁體中文</div>
          )
      },
      {
          value: 'zh-CN',
          label: (
              <div onClick={()=>modalLangChange('zh-CN')}>简体中文</div>
          )
      },
  ]

  const agreeStatement = () => {
    localStorage.setItem('agreeStatement',true)
    setStatementModal(false)
    navigate(0)
  }

  const agreeStructuredStatement = () => {
    localStorage.setItem('agreeStructuredStatement',false)
    setStructuredStatementModal(false)
  }

  const modalLangChange = (val) => {
    localStorage.setItem("btl.lang", val)
    window.dispatchEvent(new Event("storage"));
    i18n.changeLanguage(val)
    setModalLang(val);
    setLang(val)
  }

  const langChange = (val) => {
    localStorage.setItem("btl.lang", val)
    window.dispatchEvent(new Event("storage"));
    i18n.changeLanguage(val)
    lang = val;
    setLang(lang);
  }

  const rejectStatement = () => {
      setStructuredStatementModal(false)
      navigate('/')
  }

    const linkClick = (type,data) => {
      let oldAnchorPoint = sessionStorage.getItem('anchorPoint');
      if (oldAnchorPoint === data) {
        console.log(1);
        sessionStorage.removeItem('anchorPoint')
        // sessionStorage.setItem('anchorPoint','')
        sessionStorage.setItem('anchorPoint',data)
      }else{
        sessionStorage.setItem('anchorPoint',data)
      }
      window.dispatchEvent(new Event("setanch"))
      let path = location.pathname.split('/')[1];
      if (path !== type) {
        navigate(`/${type}`)
      } 
    }

    const handleScroll = () => {
      let header = document.getElementsByClassName('Header');
      if ( window.pageYOffset >= 100 ) {
          header[0].classList.add('Header-fix');
          headerClass = 'Header-fix';
          setHeaderClass(headerClass)
      }
      if ( window.pageYOffset === 0 ) {
          header[0].classList.remove('Header-fix');
          headerClass = '';
          setHeaderClass(headerClass)
      }
    }

    const logout = () => {
        localStorage.removeItem("TOKEN");
        localStorage.removeItem("USER");
        navigate(0)
        message.success("退出登录成功")
    }

    const setOpen = (type) => {
        mobileMenuOpen[type] = !mobileMenuOpen[type];
        if (type === 'about') {
            mobileMenuOpen.insights = false;
            mobileMenuOpen.service = false;
        }else if (type==='service') {
            mobileMenuOpen.about = false;
            mobileMenuOpen.insights = false;
        }else {
            mobileMenuOpen.about = false;
            mobileMenuOpen.service = false;
        }
        setMobileMenuOpen({...mobileMenuOpen})
    }

    const menuHandle = () => {
        showMune = !showMune;
        setShowMenu(showMune);
        console.log("showMenu---",showMune);
    }

    const handleOpenChange = (flag,type) => {
        checkDrop = type;
        setCheckDrop(checkDrop);
        if (flag) {
          document.addEventListener('mousewheel', handleMouseWheel);
        } else {
          document.removeEventListener('mousewheel', handleMouseWheel);
        }
        if (checkDrop === 'about') {
          aboutOpen = flag;
          setAboutOpen(aboutOpen)
        }else if (checkDrop === 'services') {
          servicesOpen = flag;
          setServicesOpen(servicesOpen)
        }else if (checkDrop === 'insights') {
          insightsOpen = flag;
          setInsightsOpen(insightsOpen)
        }else if (checkDrop === 'login') {
          loginOpen = flag;
          setLoginOpen(loginOpen)
        }else if (checkDrop === 'AMC') {
          AMCOpen = flag;
          setAMCOpen(AMCOpen)
        }
    }

    const handleMouseWheel = () => {
      if (checkDrop === 'about') {
        setAboutOpen(false)
      }else if (checkDrop === 'services') {
        setServicesOpen(false)
      }else if (checkDrop === 'insights') {
        setInsightsOpen(false)
      }else if (checkDrop === 'login') {
        setLoginOpen(false)
      }
      document.removeEventListener('mousewheel', handleMouseWheel);
    }
  
    useEffect(()=>{
      window.addEventListener('scroll',handleScroll,true)
    },[])


    useEffect(() => {
        isAbout = location.pathname.indexOf("/about") === -1 ? false : true;
        setIsAbout(isAbout);
    },[location])


    useEffect(()=>{
      showMune = false;
      setShowMenu(showMune)
      let token = localStorage.getItem('TOKEN');
      let user = JSON.parse(localStorage.getItem("USER"));
      if (token && user) {
          userInfo = user;
          setUserInfo({...userInfo})
      }
    },[location])

    useEffect(()=>{
      window.addEventListener('setanch',()=>{
          if (sessionStorage.getItem('anchorPoint')) {
              anchor = sessionStorage.getItem('anchorPoint')
              setAnchor(anchor)
          }
      })
      return window.addEventListener('setanch',()=>{
          if (sessionStorage.getItem('anchorPoint')) {
              anchor = sessionStorage.getItem('anchorPoint')
              setAnchor(anchor)
          }
      })
  },[])

    useEffect(()=>{
        setShowMenu(false)
    },[anchor])

    useEffect(()=>{
        if (!statementOpen) {
            setStatementModal(true)
        }
        if (!structuredStatementOpen && location.pathname.indexOf('AMC') > -1 && statementOpen) {
            setStructuredStatementModal(true)
        }
    },[location])



    return (
        <div className={`container ${width < 765 && 'containerMobile'} ${width >= 766 && width <= 1366 && 'containerIpad'}`}>
            {/* 国际化 */} 
            {/* {
                LOCALES_LIST.map(e => 
                    <Link key={e.value} to={{pathname: location.pathname, hash: e.value}} >
                        {e.label}
                    </Link>  
                )
            } */}
            
            <Link to="/">
                <div className="logo">
                    {
                        headerClass === 'Header-fix' ? 
                        <img 
                            className="fillImg" 
                            src={require(`@/assets/images/img${width < 481 ? '/mobile' : ''}/img-logo.png`)}
                        />
                        :
                        <img 
                            className="fillImg" 
                            src={ 
                                // isAbout
                                // ? require("@/assets/images/img/img-logo-dark.png") 
                                require(`@/assets/images/img${width < 481 ? '/mobile' : ''}/img-logo.png`)
                            } 
                            alt="" 
                        />
                    }
                </div>
            </Link>
            <div className="list">
                {
                    width > 764 ? <ul>
                        <li>
                            <Dropdown
                                menu={{
                                items,
                                }}
                                placement="bottom"
                                open={aboutOpen}
                                onOpenChange={(e)=>handleOpenChange(e,'about')}
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                            >
                                <a onClick={()=>linkClick('about','')} >
                                    {t("header.title1")}
                                </a>
                            </Dropdown>
                        </li>
                        <li>
                            <Dropdown
                                menu={{
                                  items:serviceItems
                                }}
                                placement="bottom"
                                open={servicesOpen}
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                onOpenChange={(e)=>handleOpenChange(e,'services')}
                            >
                                <a onClick={()=>linkClick('services','')}>{t("header.title2")}</a>
                            </Dropdown>
                        </li>
                        <li>
                            <Dropdown
                                menu={{
                                  items:insightsItems
                                }}
                                placement="bottom"
                                open={insightsOpen}
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                onOpenChange={(e)=>handleOpenChange(e,'insights')}
                            >
                                <a onClick={()=>linkClick('insights','')}>{t("header.title3")}</a>
                            </Dropdown>
                        </li>
                        <li>
                            <Dropdown
                                menu={{
                                  items:Structured_ProductsItems
                                }}
                                placement="bottom"
                                open={AMCOpen}
                                getPopupContainer={triggerNode => triggerNode.parentElement}
                                onOpenChange={(e)=>handleOpenChange(e,'AMC')}
                            >
                                <a onClick={()=>linkClick('AMC','')}>{t("header.title5")}</a>
                            </Dropdown>
                        </li>
                        {/* <li>
                          <a onClick={()=>linkClick('AMC','')}>{t("header.title5")}</a>
                        </li> */}
                        {
                            Object.keys(userInfo).length === 0 ? LIST.map((e,i) => 
                                <li key={i}>
                                    <Link to={e.href}>
                                        {e.label}
                                    </Link>
                                </li>
                            ) :
                            <li>
                                <Dropdown
                                  placement="bottom"
                                  menu={{
                                    items: logoutItem
                                  }}
                                  open={loginOpen}
                                  onOpenChange={(e)=>handleOpenChange(e,'login')}
                                >
                                  <span className="username">{userInfo.nickname}</span>

                                </Dropdown>
                            </li>
                        }
                    </ul>
                    :
                    <ul className={showMune ? 'openMenu' : ""}>
                        <li>
                            {/* <Dropdown
                                menu={{
                                items,
                                }}
                                placement="bottom"
                            >
                                <span>{t("header.title1")}</span>
                            </Dropdown> */}
                            <p className="menu-title" onClick={()=>setOpen('about')}>{t("header.title1")}</p>
                            <div className={`down-menu ${mobileMenuOpen.about && 'down-menu-open'}`}>
                                {
                                    items.map((e,i)=>(
                                        <div className="down-menu-item" key={e.key}>{e.label}</div>
                                    ))
                                }
                            </div>
                            {
                                mobileMenuOpen.about ? <img src={require('@/assets/images/img/mobile/img-up.png')} />
                                : <img src={require('@/assets/images/img/mobile/img-down.png')} />
                            }
                        </li>
                        <li>
                            {/* <Dropdown
                                menu={{
                                  items:serviceItems
                                }}
                                placement="bottom"
                            > 
                                <span>{t("header.title2")}</span>
                            </Dropdown>
                            <img src={require('@/assets/images/img/mobile/img-down.png')} /> */}
                            <p className="menu-title" onClick={()=>setOpen('service')}>{t("header.title2")}</p>
                            <div className={`down-menu ${mobileMenuOpen.service && 'down-menu-open'}`}>
                                {
                                    serviceItems.map((e,i)=>(
                                        <div className="down-menu-item" key={e.key}>{e.label}</div>
                                    ))
                                }
                            </div>
                            {
                                mobileMenuOpen.service ? <img src={require('@/assets/images/img/mobile/img-up.png')} />
                                : <img src={require('@/assets/images/img/mobile/img-down.png')} />
                            }
                        </li>
                        <li>
                            {/* <Dropdown
                                menu={{
                                  items:insightsItems
                                }}
                                placement="bottom"
                            >
                                <span>{t("header.title3")}</span>
                            </Dropdown>
                            <img src={require('@/assets/images/img/mobile/img-down.png')} /> */}
                            <p className="menu-title" onClick={()=>setOpen('insights')}>{t("header.title3")}</p>
                            <div className={`down-menu ${mobileMenuOpen.insights && 'down-menu-open'}`}>
                                {
                                    insightsItems.map((e,i)=>(
                                        <div className="down-menu-item" key={e.key}>{e.label}</div>
                                    ))
                                }
                            </div>
                            {
                                mobileMenuOpen.insights ? <img className="img-open" src={require('@/assets/images/img/mobile/img-up.png')} />
                                : <img className="img-close" src={require('@/assets/images/img/mobile/img-down.png')} />
                            }
                        </li>
                        <li className="AMC-li">
                            <Link to={'/AMC'}>
                                {t("header.title5")}
                            </Link>
                        </li>
                        {/* {
                            Object.keys(userInfo).length === 0 ? LIST.map((e,i) => 
                                <li className="login user" key={i} onClick={()=>navigator(e.href)}>
                                    <Link to={e.href}>
                                        {e.label}
                                    </Link>
                                </li>
                            ) :
                            <li className="userName user">
                                <Dropdown
                                  placement="bottom"
                                  menu={{
                                    items: logoutItem
                                  }}
                                >
                                  <span className="username">{userInfo.nickname}</span>

                                </Dropdown>
                            </li>
                        } */}
                    </ul>
                }
            </div>
            <div className="selAndIcon">
                <div className="icon">
                    <Select 
                      options={LOCALES_LIST} 
                      value={lang}
                      onChange={langChange}
                      placement={"bottomRight"}
                      getPopupContainer={triggerNode => triggerNode.parentNode}
                    />
                </div>
                {
                    width < 765 ? <div className="menuIcon" onClick={()=>menuHandle()}>
                        {
                          showMune ? <img src={require('@/assets/images/icon/mobile/icon-header-close.png')} className="close" /> 
                          :
                          <img src={require('@/assets/images/icon/mobile/icon-menu.png')} />
                        }
                    </div> 
                    : ''
                }

            </div>
            <Modal
                open={statementModal}
                className={`statementModal ${width < 481 ? 'statementModalMobile' : ''}`}
                footer={false}
            >
                <div className="statement-content">
                    <div className="header">
                        <div className="header-title">{t("indexModal.title")}</div>
                        <div className="header-img">
                            <img className="lang-icon" src={require(`../assets/images/icon/icon-modal-lang-${lang}.png`)} />
                            {/* <Dropdown
                                menu={{
                                    items: modalLangList
                                }}
                                placement="bottom"
                                
                            >
                                <img className="pull-icon" src={require(`../assets/images/icon/icon-modal-lang-pull.png`)} />
                            </Dropdown> */}
                            <Select
                                options={modalLangList}
                                placement="bottom"
                                bordered={false}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                            >
                                <img className="pull-icon" src={require(`../assets/images/icon/icon-modal-lang-pull.png`)} />
                            </Select>
                        </div>
                    </div>
                    <div className="contents">
                        <p className="content-text">{t("indexModal.content1")}</p>
                        <p className="content-text">{t("indexModal.content2")}</p>
                        <p className="content-text">{t("indexModal.content3")}</p>
                    </div>
                    <div className="footer">
                        <div className="Decline" onClick={()=>setStatementModal(false)}>Decline</div>
                        <div className="Accept" onClick={()=>agreeStatement()}>Accept</div>
                    </div>
                </div>
            </Modal>
            <Modal
                open={structuredStatementModal}
                className={`statementModal ${width < 481 ? 'statementModalMobile' : ''}`}
                footer={false}
            >
                <div className="statement-content">
                    <div className="header">
                        <div className="header-title">{t("structuredStatement.title")}</div>
                        <div className="header-img">
                            <img className="lang-icon" src={require(`../assets/images/icon/icon-modal-lang-${lang}.png`)} />
                            {/* <Dropdown
                                menu={{
                                    items: modalLangList
                                }}
                                placement="bottom"
                                
                            >
                                <img className="pull-icon" src={require(`../assets/images/icon/icon-modal-lang-pull.png`)} />
                            </Dropdown> */}
                            <Select
                                options={modalLangList}
                                placement="bottom"
                                bordered={false}
                                getPopupContainer={triggerNode => triggerNode.parentNode}
                            >
                                <img className="pull-icon" src={require(`../assets/images/icon/icon-modal-lang-pull.png`)} />
                            </Select>
                        </div>
                    </div>
                    <div className="contents">
                        <p className="content-text">{t("structuredStatement.content1")}</p>
                        <p className="content-text">{t("structuredStatement.content2")}</p>
                        <p className="content-text">{t("structuredStatement.content3")}</p>
                        <p className="content-text">{t("structuredStatement.content4")}</p>
                        <p className="content-text">{t("structuredStatement.content5")}</p>
                        <p className="content-text">{t("structuredStatement.content6")}</p>
                        <p className="content-text">{t("structuredStatement.content7")}</p>
                    </div>
                    <div className="footer">
                        <div className="Decline" onClick={()=>rejectStatement()}>Decline</div>
                        <div className="Accept" onClick={()=>agreeStructuredStatement()}>Accept</div>
                    </div>
                </div>
            </Modal>
        </div>
          
    )
    
}