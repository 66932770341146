import "@/assets/styles/view-style/services.scss";
import "@/assets/styles/component-style/serviceItem.scss";
import AssetManagementItem from "../components/CustomItem/services/AssetManagementItem";
import InvestmentManagementItem from "../components/CustomItem/services/InvestmentManagementItem";
import AreaCoverageItem from "../components/CustomItem/services/AreaCoverageItem";
import BenefitItem from "../components/CustomItem/services/BenefitItem";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useViewport } from "../hooks/useViewport";

export default function Services(params) {
    const {t} = useTranslation()
    const location = useLocation()
    const {width} = useViewport()
    let [anchor,setAnchor] = useState(sessionStorage.getItem('anchorPoint'))
    
    const ASSETMANAGEMENTS = [
        {
            title: t("services.services-plan-title1"),
            content: t("services.services-plan-desc1"),
            url: "Investment_Advisory_Mandates",
        },
        {
            title: t("services.services-plan-title2"),
            content: t("services.services-plan-desc2"),
            url: "Discretionary_Mandates",
        },
        {
            title: t("services.services-plan-title3"),
            content: t("services.services-plan-desc3"),
            url: "External_Asset_Manager",
        },
        {
            title: t("services.services-plan-title4"),
            content: t("services.services-plan-desc4-li1"),
            url: "Other_Investment_Services",
        },
    ]

    const INVESTMENTMANAGEMENT = [
        {
            title: t("services.services-investment-subtitle1"),
            content: [
                t("services.services-investment-desc1-li1"),
                t("services.services-investment-desc1-li2")
            ],
            url: "Alternative_Investment_Funds",
        },
        {
            title: t("services.services-investment-subtitle2"),
            content: [
                t("AMC.content-title1")
            ],
            url: "AMC",
        },
    ]

    const COVERAGE = [
        t("services.services-advisory-desc3"),
        t("services.services-advisory-desc4"),
        t("services.services-advisory-desc5"),
        
    ]

    const COVERAGE2 = [
        t("services.services-advisory-desc6"),
        t("services.services-advisory-desc7"),
        t("services.services-advisory-desc8")
    ]

    const BENEFITS = [
        {
            img: require("@/assets/images/img/img-benefit-market.png"),
            title: t("services.services-business-subtitle1"),
            contents: [
                t("services.services-business-desc1-li1")
            ],
            links: [
            ],
            url: "HK_Market",
        },
        {
            img: require("@/assets/images/img/img-benefit-connect.png"),
            title: t("services.services-business-subtitle2"),
            contents: [
                t("services.services-business-desc2-li2")
            ],
            // links: [
            //     {
            //         content: t("services.services-business-desc2-li4"),
            //         url: ""
            //     },
            //     {
            //         content: t("services.services-business-desc2-li6"),
            //         url: ""
            //     },
            // ],
            url: "Stock_Connect",
        },
        {
            img: require("@/assets/images/img/img-benefit-global.png"),
            title: t("services.services-business-subtitle3"),
            contents: [
                t("services.services-business-desc3-li1")
            ],
            links: [
            ],
            url: "Global_Market",
        },
        {
            img: require("@/assets/images/img/img-benefit-future.png"),
            title: t("services.services-business-subtitle4"),
            contents: [
                t("services.services-business-desc4-li1"),
            ],
            links: [
            ],
            url: "Futures_Options",
        },
        {
            img: require("@/assets/images/img/img-benefit-structured.png"),
            title: t("services.services-business-subtitle5"),
            contents: [
                t("services.services-business-desc5-li1"),
            ],
            links: [
            ],
            url: "Structured_Products",
        },
        {
            img: require("@/assets/images/img/img-benefit-fixed.png"),
            title: t("services.services-business-subtitle6"),
            contents: [
                t("services.services-business-desc6-li1"),
            ],
            links: [
            ],
            url: "Fixed_Income",
        },
    ]

    const scrollToAnchor = (anchorName) => {
        let anchorElement = document.getElementsByClassName(`Services-${anchorName}`)[0];
        anchorElement.scrollIntoView({ behavior: 'smooth', block:'start' })
    }

    useEffect(() => {
        if (location.search !== "") {
            setTimeout(() => {
                scrollToAnchor(location.search.split("?")[1]);
            }, 20);
        }
    },[location])

    useEffect(()=>{
        let point = sessionStorage.getItem('anchorPoint');
        if (point) {
            setTimeout(()=>{
                scrollToAnchor(point)
            },200)
        }
    },[anchor])

    useEffect(()=>{
        window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
        return window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
    },[])

    return <div className={`Services ${width < 765 && 'ServicesMobile'} ${width >= 766 && width <= 1366 && 'ServicesIpad'}`}>
        <div className="Services-wealth">
            <div className="wealth-content">
                <div className="content-text">
                    <p className="service-title fontMedium">
                        {t("services.services-plan-title")}
                    </p>
                    <p className="service-large-title fontBlod">
                        {t("index.services-subtitle5")}
                    </p>
                    <p className="service-content">{t("index.services-desc5")}</p>
                    <a href={`/details?Wealth_Inheritance`} className="learn-btn fontMedium">{t("others.learn")}<img src={require('@/assets/images/img/img-right.png')} /></a>
                </div>
                <div className="content-img">
                    <img src={require('@/assets/images/img/wealth-img.png')} />
                </div>
            </div>
        </div>

        <div className="layout-content">    
            {/* 资产管理 */}
            <div className="Services-one Services-Asset_Management services-mobile">
                <div className="content m-auto">
                    <div className="content-l">
                        <p className="service-subtitle-title fontMedium">{t("services.services-plan-title")}</p>
                        <p className="service-large-title-colorful service-large-title fontBlod">{t("services.services-plan-btitle")}</p>
                        <p className="service-content">{t("services.services-plan-desc")}</p>
                    </div>
                    <div className="content-r">
                        {
                            ASSETMANAGEMENTS.map((e, i) =>
                                <AssetManagementItem key={i} title={e.title} num={i} content={e.content} url={e.url}></AssetManagementItem>
                            )
                        }
                    </div>
                </div>
            </div>
            {/* 投资管理 */}
            <div className="Services-two Services-Investment_Management services-mobile">
                <div className="content m-auto">
                    <div className="content-t">
                        <p className="service-subtitle-title fontMedium">{t("services.services-plan-title")}</p>
                        <p className="service-large-title-colorful service-large-title fontBlod">{t("services.services-investment-title")}</p>
                        <p className="service-content">{t("services.services-investment-desc")}</p>
                    </div>
                    <div className="content-b">
                        {
                            INVESTMENTMANAGEMENT.map((e, i) =>
                                <InvestmentManagementItem key={i} title={e.title} content={e.content} url={e.url}></InvestmentManagementItem>
                            )
                        }
                    </div>
                </div>
            </div>

            {/* 投资咨询 */}
            <div className="Services-three Services-Investment_Advisory services-mobile">
                <div className="content m-auto">
                    <div className="content-l">
                        {/* <p className="service-subtitle-title fontMedium">{t("services.services-plan-title")}</p>
                        <p className="service-large-title-colorful service-large-title fontBlod">{t("services.services-advisory-title")}</p> */}
                        <p className="service-content">{t("services.services-advisory-desc1")}</p>
                        <p className="service-content">{t("services.services-advisory-desc1-sub")}</p>
                    </div>
                    <p className="service-title fontMedium">{t("services.services-advisory-desc2")}</p>
                    <div className="content-list">
                        <div className="content-r">
                            {
                                COVERAGE.map((e, i) =>
                                    <AreaCoverageItem key={i} content={e}></AreaCoverageItem>
                                )
                            }
                        </div>
                        <div className="content-r">
                            {
                                COVERAGE2.map((e, i) =>
                                    <AreaCoverageItem key={i} content={e}></AreaCoverageItem>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

        </div>
            {/* 概览 & 福利 */}
            <div className="Services-four Services-Brokerage_Business services-mobile">
                <div className="content m-auto">
                        <div className="content-t">
                            <p className="service-subtitle-title fontMedium">{t("services.services-plan-title")}</p>
                            <p className="service-large-title-colorful service-large-title fontBlod">{t("services.services-business-title")}</p>
                            <p className="service-title fontMedium">{t("services.services-business-subtitle")}</p>
                            <p className="line"></p>
                            <p className="service-content">{t("services.services-business-desc-li1")}</p>
                            <p className="service-content">{t("services.services-business-desc-li2")}</p>
                            <p className="service-content">{t("services.services-business-desc-li3")}</p>
                        </div>
                        <div className="content-b">
                            <div className="container">
                                {
                                    BENEFITS.map((e, i) =>
                                        <BenefitItem key={i} img={e.img} title={e.title} contents={e.contents} url={e.url}></BenefitItem>
                                    )
                                }
                            </div>
                        </div>
                    </div>
            </div>

    </div>
}