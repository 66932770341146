import { useEffect } from "react"
import { useTranslation } from "react-i18next"

export default function ReportItem(props) {
     const { bg, title, desc, file, lang, type } = props
     const {t} = useTranslation()

    const clickHandle = () => {
        if ( type !== 'specialReport' ) {
            window.open(file)
        }
    }


     return (
        <div className="ReportItem" onClick={()=>clickHandle()}>
            <img src={bg} className="bg"></img>
            <div className="content-container">
                <p className={`title insight-subtitle fontBlod f32 ${type !== 'researchReport' ? '' : 'researchTitle'}`}>{title[lang]}</p>
                <p className={`insight-content f20 ${type === 'researchReport' && 'researchContent'}`}>{desc[lang]}</p>
            </div>
            {
                type === 'specialReport' && <div className="download-btn" onClick={()=>window.open(file)}>
                    {t("others.download")}
                </div>
            }
            <div className="mask">
                <img src={require(`@/assets/images/icon/icon-insight-download.png`)} alt="" className="icon"></img>
                <p className="insight-content">{t("others.download")}</p>
            </div>
        </div>
     )
}