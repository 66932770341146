import "@/assets/styles/view-style/insights.scss";
import "@/assets/styles/component-style/insightItem.scss";
import ReportItem from "../components/CustomItem/insight/ReportItem";
import EducationItem from "../components/CustomItem/insight/EducationItem";
import { useEffect, useState } from "react";
import { getMarketReview, getSpecial, getResearch } from "../request/api";
import { listProcessing } from "../utils/dataProcessing";
import { message } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useViewport } from "../hooks/useViewport";

export default function Insights(params) {
    const location = useLocation()
    const {t} = useTranslation()
    let [anchor,setAnchor] = useState(sessionStorage.getItem('anchorPoint'))
    const token = localStorage.getItem("TOKEN")
    const {width} = useViewport()

    // 展示产品评论
    let [marketReview,setMarketReview] = useState([])
    let [specialData,setSpecialData] = useState([])
    let [researchData,setResearchData] = useState([])
    let [lang,setLang] = useState(localStorage.getItem("btl.lang"))
    let [dataLang,setDataLang] = useState()

    // 获取产品评论
    const getReviewData = async () => {
        // if (token) {
            await getMarketReview({
                page: 1
            })
            .then((res) => {
                console.log("res===",res);
                if ( res.code === 0 ) {
                    let data = listProcessing('Market_view',res.data.list)
                    marketReview = data;
                    setMarketReview([...marketReview])
                }
            }).catch((err) => { 
                console.log(err);
            });
        // }
    }

    const getSpecialData = async () => {
        await getSpecial({
            page: 1
        })
        .then((res) => {
            if (res.code === 0) {
                let data = listProcessing('Special_report',res.data.list)
                specialData = data;
                setSpecialData([...specialData])
            }
        }).catch((err) => {

        });
    }

    const getResearchData = async () => {
        await getResearch({
            page: 1
        })
        .then((res) => {
            if (res.code===0){
                let data = listProcessing('Research_report',res.data.list);
                researchData = data;
                setResearchData([...researchData])
            }
        }).catch((err) => {
            
        });
    }

    useEffect(()=>{
        getSpecialData();
        getReviewData();
        getResearchData()
    },[])




    const EDUCATIONS = [
        {
            img: require(`@/assets/images/img/${width < 481 ? 'mobile/' : ''}img-insight-discretionary.png`),
            title: "Discretionary account",
            content: "2021 was a better-than-average year on many fronts. Stock market returns, economic growth, policy stimulus and inflation all ran hot while volatility was remained in check.",
        },
        {
            img: require(`@/assets/images/img/${width < 481 ? 'mobile/' : ''}img-insight-certificate.png`),
            title: "Active management certificate",
            content: "2021 was a better-than-average year on many fronts. Stock market returns, economic growth, policy stimulus and inflation all ran hot while volatility was remained in check.",
        },
        {
            img: require(`@/assets/images/img/${width < 481 ? 'mobile/' : ''}img-insight-structured.png`),
            title: "Discretionary account",
            content: "2021 was a better-than-average year on many fronts. Stock market returns, economic growth, policy stimulus and inflation all ran hot while volatility was remained in check.",
        },
    ]

    const scrollToAnchor = (anchorName) => {
        let anchorElement = document.getElementsByClassName(`insights-${anchorName}`)[0];
        anchorElement?.scrollIntoView({ behavior: 'smooth', block:'start' })
    }

    useEffect(() => {
        if (location.search !== "") {
            setTimeout(() => {
                scrollToAnchor(location.search.split("?")[1]);
            }, 20);
        }
    },[location,specialData,researchData,marketReview])


    useEffect(()=>{
        console.log("lang=====",lang);
        if (lang === 'zh-TW') {
            dataLang = 'hk';
            setDataLang(dataLang)
        }else if (lang === 'zh-CN') {
            dataLang = 'cn';
            setDataLang(dataLang);
        }else{
            dataLang = 'en';
            setDataLang(dataLang)
        }
    },[lang])

    useEffect(()=>{
        window.addEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
        return window.removeEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
    },[])

    useEffect(()=>{
        let point = sessionStorage.getItem('anchorPoint');
        if (point) {
            setTimeout(()=>{
                scrollToAnchor(point)
            },200)
        }
    },[anchor])

    useEffect(()=>{
        window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
        return window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
    },[])

    useEffect(()=>{
        console.log("marketReview===",marketReview);
    })

    return (
        <div className={`Insights ${width < 765 && 'InsightsMobile'} ${width > 1024 && 'InsightPc'} ${width >= 766 && width <= 1366 && 'InsightIpad'}`}>
            <div className="layout-content">
                {/* 简介 */}
                <div className="Insights-schema">
                    <div className="content m-auto">
                        <div className="content-t"></div>
                        <div className="content-b">
                            <p className="insight-large-title fontMedium">
                                {t("Insights.title")}
                            </p>
                            <p className="insight-content">{t("Insights.desc")}</p>
                        </div>
                    </div>
                </div>

                {/* 报告 */}
                {
                    <div className=" insights-item Insights-report insights-Special_report">
                        <div className="content m-auto">
                            <div className="content-t">
                                <p className="insight-title fontMedium">{t("Insights.subtitle2")}</p>
                                <div className="line"></div>
                                <a className="insight-content" href="list?Special_report">{t("others.viewall")}</a>
                            </div>
                            <div className={`content-b ${specialData.length === 1 && 'onlyOne'}`}>
                                {
                                    specialData.length > 0 && specialData.map((e, i) =>
                                        i < 3 && <ReportItem lang={dataLang} key={i} bg={e.image} title={e.title} desc={e.desc} file={e.file} type={'specialReport'}></ReportItem>
                                    )
                                }
                                {
                                    specialData.length === 1 || specialData.length === 2 ? <div className={`ReportItem report-empty-item ${width < 765 && 'report-empty-mobile'}`}>
                                        <p>{t("others.comming-soon")}</p>
                                    </div> : ''
                                }
                            </div>
                        </div>
                    </div>
                }

                {/* 观点 */}
                {
                    <div className="Insights-view insights-Market_review insights-item">
                    <div className="content m-auto">
                        <div className="content-t">
                            <p className="insight-title fontMedium">{t("Insights.subtitle3")}</p>
                            <div className="line"></div>
                            <a className="insight-content" href="list?Product_view">{t("others.viewall")}</a>
                        </div>
                        <div className="content-b">
                            {
                                marketReview.length > 0 && marketReview.map((e, i) =>
                                    <ReportItem key={i} lang={dataLang} bg={e.image} title={e.title} desc={e.desc} file={e.file}></ReportItem>
                                )
                            }
                            {
                                (marketReview.length === 1 || marketReview.length === 3) ? <div className={`ReportItem report-empty-item ${width < 765 && 'report-empty-mobile'}`}>
                                    <p>{t("others.comming-soon")}</p>
                                </div> : ''
                            }
                        </div>
                    </div>
                </div> }

                <div className="Insights-report insights-Research_report insights-item">
                    <div className="content m-auto">
                        <div className="content-t">
                            <p className="insight-title fontMedium">{t("Insights.subtitle1")}</p>
                            <div className="line"></div>
                            <a className="insight-content" href="list?Research_report">{t("others.viewall")}</a>
                        </div>
                        <div className="content-b">
                            {
                                researchData.length > 0 && researchData.map((e, i) =>
                                    <ReportItem lang={dataLang} key={i} bg={e.image} title={e.title} desc={e.desc} file={e.file} type={'researchReport'}></ReportItem>
                                ) 
                            }
                            {
                                researchData.length === 1 || researchData.length === 3 ? <div className={`ReportItem report-empty-item ${width < 765 && 'report-empty-mobile'}`}>
                                    <p>{t("others.comming-soon")}</p>
                                </div> : ''
                            }
                        </div>
                    </div>
                </div>

                


                {/* 教育 */}
                {/* <div className="Insights-education insights-Investor_education insights-item">
                    <div className="content m-auto">
                        <div className="content-l">
                            <p className="insight-large-title fontBlod">Investor education</p>
                            <p className="insight-content">BTL's Investment Advisory & Research team consists of high-caliber research analysts with superior academic backgrounds and comprehensive experience in international capital markets.</p>
                            <p className="insight-content">Our Investment Advisory and Research team covers global macroeconomics, financial markets, and commodities to the top 100 US-listed companies spanning various sectors.</p>
                        </div>
                        <div className="content-r">
                            {
                                EDUCATIONS.map((e, i) =>
                                    <EducationItem key={i} img={e.img} title={e.title} content={e.content}></EducationItem>
                                )
                            }
                        </div>
                    </div>
                </div> */}
            </div>

        </div>
    )
}