export default function ServiceItem(props) {
    
    const { img, title, content } = props;


    return (
        <div className="ServiceItem">
            <img src={img} alt="" />
            <p className="index-subtitle item-title fontMedium">{title}</p>
            <p className="index-content item-content">{content}</p>
        </div>
    )
}