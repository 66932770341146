import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";


export default function BenefitItem(props) {
    const { img, title, contents, links, url} = props;
    const {t} = useTranslation()

    const navigateTo = useNavigate();

    const toIndex = () => {
        navigateTo(url)
    }

    return (
        <div className="BenefitItem">
            <img src={img}></img>
            <p className="title service-subtitle fontMedium">{title}</p>
            <div className="benefitItem-content">
                {
                    contents.map((e, i) =>
                        <p key={i} className="desc service-content">{e}</p>
                    )
                }
            </div>
            <div className="url">
                <p className="service-content">
                    <a className="read-more" href={`/details?${url}`} style={{}}>{t("others.learn")}</a>
                </p>
            </div>
        </div>
    )
}