export const detectZoom = () => {
    let ratio = 0,
      screen = window.screen,
      ua = navigator.userAgent.toLowerCase();
    if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio;
    } else if (~ua.indexOf('msie')) {
      if (screen.deviceXDPI && screen.logicalXDPI) {
        ratio = screen.deviceXDPI / screen.logicalXDPI;
      }
    } else if (
      window.outerWidth !== undefined &&
      window.innerWidth !== undefined
    ) {
      ratio = window.outerWidth / window.innerWidth;
    }
    if (ratio) {
      ratio = Math.round(ratio * 100);
    }
    return ratio;
  };

export const selectZoom = () => {
  const m = detectZoom();
  let zoom = 0;
  if (window.screen.width * window.devicePixelRatio >=(1920 * 2)) {
    switch (m) {
      // 4k ==> 100%
      case 100:
        zoom = 100 / (0.625 * 2560);
        break;
        // 4k ==> 125%
      case 125:
        zoom = 100 / (0.625 * 2560);
        break;
        // 4k ==> 150%
      case 150:
        zoom = 100 / (0.75 * 2560);
        break;
        // 4k ==> 175%
      case 175:
        zoom = 100 / (0.874715 * 2560);
        break;
        // 4k ==> 200%
      case 200:
        zoom = 1920 / 2560;
        break;
        // 4k ==> 225%
      case 225:
        zoom = 100 / (1.12485 * 2560);
        break;
    
      default:
        break;
    }
}
else if(m === 100 && window.screen.width === 1920 && window.devicePixelRatio === 1){
  zoom = 1920 / 2560;
}
else if (window.screen.width <= 1921 && window.screen.width >= 1440) {
  zoom = 1440 / 1920;
}
if (zoom !== 0) {
  zoom = (1 - zoom) + 1
  // zoom = 1 + zoom
  zoom = 0
}

return zoom
}