import CustomNews from "../components/CustomNews";
import "@/assets/styles/view-style/news.scss"
import { useLocation, useParams } from "react-router-dom";
import { useEffect,useState } from "react";
import { getNews } from "../request/api";
import { singleProcessing } from "../utils/dataProcessing";
import { useViewport } from "../hooks/useViewport";

export default function News(params) {
    const location = useLocation()
    const {width} = useViewport()
    const { id } = useParams();

    let [newsId,setNewsId] = useState()
    let [data,setData] = useState({})
    let [page,setPage] = useState()
    
    const getData = async () => {
        await getNews({
            page: page
        })
        .then((res) => {
            console.log('data====',res);
            res.data.list.map((e,i)=>{
                if (e.id == id) {
                    let obj = singleProcessing('news',e)
                    console.log("obj----",obj);
                    data = obj;
                    setData({...data})
                }
            })
        }).catch((err) => {
            console.log(err);
        });
    }

    useEffect(()=>{
        getData()
    },[page])

    useEffect(()=>{
        let p = location.search.split('?')[1]
        page = p;
        setPage(page)
    },[location])

    return (
        <div className={`News ${width < 481 && 'NewsMobile'} ${width > 765 && width <= 1366 && 'NewsIpad'}`}>
            <div className="content">
            <CustomNews 
                // img={require("@/assets/images/bg/bg-about-news.png")}
                img={data.image}
                type="news" 
                title={data.title}
                date={data.date}
                content={data.content}
            />
            </div>
        </div>
    )
}