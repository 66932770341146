import Index from "@/views/Index"
import About from "@/views/About"
import Services from "@/views/Services"
import News from "@/views/News";
import Insights from "@/views/Insights";
import List from "../views/List";
import Details from "../views/Details";
import Login from "../views/Login";
import AMC from "../views/AMC";
import { Navigate } from "react-router-dom";

const statement = localStorage.getItem('agreeStatement')

const routes = [
    { 
      path: "/",
      element: <Index />,
    },
    { 
      path: "/about",
      element: statement ? <About /> : <Navigate to="/" replace />,
    },
    { 
      path: "/services",
      element: statement ? <Services /> : <Navigate to="/" replace />,
    },
    { 
      path: "/news/:id",
      element: statement ? <News /> : <Navigate to="/" replace />,
    },
    {
      path: "/insights",
      element: statement ? <Insights /> : <Navigate to="/" replace />
    },
    {
      path: "/list",
      element: statement ? <List /> : <Navigate to="/" replace />
    },
    {
      path: "/details",
      element: statement ? <Details /> : <Navigate to="/" replace />
    },
    {
      path: "/AMC",
      element: statement ? <AMC /> : <Navigate to="/" replace />
    },
    // {
    //   path: "/login",
    //   element: <Login />
    // }
];
export default routes