import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useViewport } from '../../../hooks/useViewport';

export default function InsightItem(props) {

    const { img, title, desc, file, dataLang } = props;
    const {t} = useTranslation()
    const {width} = useViewport()
    const statement = localStorage.getItem('agreeStatement');

    const clickHandle = () => {
        if (statement) {
            window.open(file)
        }
    }
    
    return (
        <div className="InsightItem" onClick={clickHandle}>
            {
                width < 481 ? <img 
                    className='download-img' 
                    src={require('@/assets/images/icon/icon-insight-download.png')} 
                    onClick={clickHandle}
                />
                :
                <div className='download'>
                    <img src={require('@/assets/images/icon/icon-insight-download.png')} />
                    <p>{t("others.download")}</p>
                </div>
            }
            <img src={img} className="bg"></img>
            <div className='insight-content'>
                <p className="index-subtitle item-title">{title[dataLang]}</p>
                <p className="index-content item-content">{desc[dataLang]}</p>
            </div>
        </div>
    )
}