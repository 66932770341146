import Map from "./map"
import "@/assets/styles/component-style/map.scss"
import { useTranslation } from "react-i18next";
import { useViewport } from "../../hooks/useViewport";

export default function CustomMap(props) {
    const {t} = useTranslation()
    const {width} = useViewport()

    const { Mapwidth, height } = props;

    const CONTACT = [
        {
            icon: "icon-contact-addr.png",
            label: t("index.contact-desc1")
        },
        {
            icon: "icon-contact-tel.png",
            label: t("index.contact-desc2")
        },
        {
            icon: "icon-contact-fax.png",
            label: t("index.contact-desc3")
        },
        {
            icon: "icon-contact-email.png",
            label: t("index.contact-desc4")
        }
    ]

    return <div className="CustomMap wordselect">
        <div className="detail">
            <ul>
                {
                    CONTACT.map((e,i) => 
                        <li key={i}>
                            <img src={require(`@/assets/images/icon${width < 481 ? '/mobile' : ''}/${e.icon}`)} alt="" />
                            {e.label}
                        </li>
                    )
                }
            </ul>
        </div>
        <Map width={Mapwidth} height={height} />
    </div>
}