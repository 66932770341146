import { useViewport } from "../../../hooks/useViewport";
import { useNavigate } from "react-router-dom";


export default function EducationItem(props) {
    const navigate = useNavigate()

    const { title, content, link } = props;
    const {width} = useViewport()
    
    return (
        <div className="EducationItem" onClick={()=>navigate(link)}>
            {/* {
                width < 481 ? <img src={require("@/assets/images/img/mobile/img-education-bg.png")} /> : ''
            } */}
            <p className="index-subtitle item-title fontNormal">{title}</p>
            <p className="index-content item-content">{content}</p>
        </div>
    )
}