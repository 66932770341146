import "@/assets/styles/view-style/about.scss";
import "@/assets/styles/component-style/aboutItem.scss";
import ValueItem from "../components/CustomItem/about/ValueItem";
import WealthItem from "../components/CustomItem/about/WealthItem";
import TeamItem from "../components/CustomItem/about/TeamItem";
import NewsItem from "../components/CustomItem/about/NewsItem";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getNews } from "../request/api";
import { listProcessing, singleProcessing } from "../utils/dataProcessing";
import { useTranslation } from "react-i18next";
import { useViewport } from "../hooks/useViewport";



export default function About(params) {

    const location = useLocation();
    const navigate = useNavigate()
    const { t } = useTranslation()
    let [anchor,setAnchor] = useState(sessionStorage.getItem('anchorPoint'))
    const {width} = useViewport()

    let [newsData,setNewsData] = useState([])
    let [lang,setLang] = useState(localStorage.getItem("btl.lang"))
    let [dataLang,setDataLang] = useState()


    const getNewsData = async () => {
        await getNews({
            page: 1
        })
        .then((res) => {
            let data = listProcessing("news",res.data.list)
            newsData = data;
            setNewsData([...newsData])
        }).catch((err) => {
            console.log(err);
        });
    } 

    useEffect(()=>{
        getNewsData()
    },[])


    const WEALTH = [
        {
            img: require("@/assets/images/img/img-wealth-affordably.png"),
            title: t("about.about-beliefs-desc5-title1"),
            content: t("about.about-beliefs-desc5-content1")
        },
        {
            img: require("@/assets/images/img/img-wealth-safely.png"),
            title: t("about.about-beliefs-desc5-title2"),
            content: t("about.about-beliefs-desc5-content2")
        },
        {
            img: require("@/assets/images/img/img-wealth-easily.png"),
            title: t("about.about-beliefs-desc5-title3"),
            content: t("about.about-beliefs-desc5-content3")
        },
        {
            img: require("@/assets/images/img/img-wealth-expert.png"),
            title: t("about.about-beliefs-desc5-title4"),
            content: t("about.about-beliefs-desc5-content4")
        },
    ]

    const TEAM = [
        {
            icon: require("@/assets/images/team/chen.jpg"),
            name: t("about.about-team-subtitle1"),
            job: t("about.about-team-subtitle1-job"),
            desc: [
                t("about.about-team-desc1-li1"),
                t("about.about-team-desc1-li2"),
                t("about.about-team-desc1-li3")
            ]
        },
        {
            icon: require("@/assets/images/team/yu.jpg"),
            name: t("about.about-team-subtitle2"),
            job: t("about.about-team-subtitle2-job"),
            desc: [
                t("about.about-team-desc2-li1"),
                t("about.about-team-desc2-li2"),
                t("about.about-team-desc2-li3")
            ]
        },
        {
            icon: require("@/assets/images/team/feng.jpg"),
            name: t("about.about-team-subtitle4"),
            job: t("about.about-team-subtitle4-job"),
            desc: [
                t("about.about-team-desc4-li1"),
                t("about.about-team-desc4-li2"),
                t("about.about-team-desc4-li3")
            ]
        },
        {
            icon: require("@/assets/images/team/limenglu.jpg"),
            name: t("about.about-team-subtitle3"),
            job: t("about.about-team-subtitle3-job"),
            desc: [
                t("about.about-team-desc3"),
                t("about.about-team-desc3-li1"),
                t("about.about-team-desc3-li2"),
            ]
        },
    ]

    const NEWS = [
        {
            img: require("@/assets/images/bg/bg-about-news.png"),
            title: "Investment Management Services",
            content: "BTL provides innovative Investment Management Services for long-term excess return.",
            time: "Feb 29, 2023",
        },
    ]

    const scrollToAnchor = (anchorName) => {
        let anchorElement = document.getElementsByClassName(`About-${anchorName}`)[0];
        anchorElement.scrollIntoView({ behavior: 'smooth', block:'start' })
    }

    useEffect(() => {
        if (location.search !== "") {
            setTimeout(() => {
                scrollToAnchor(location.search.split("?")[1]);
            }, 20);
        }
    },[location])

    useEffect(()=>{
        if (lang === 'zh-TW') {
            dataLang = 'hk';
            setDataLang(dataLang)
        }else if (lang === 'zh-CN') {
            dataLang = 'cn';
            setDataLang(dataLang);
        }else{
            dataLang = 'en';
            setDataLang(dataLang)
        }
    },[lang])

    useEffect(()=>{
        window.addEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
        return window.removeEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
    },[])

    useEffect(()=>{
        let point = sessionStorage.getItem('anchorPoint');
        if (point) {
            setTimeout(()=>{
                scrollToAnchor(point)
            },200)
        }
    },[anchor])

    useEffect(()=>{
        window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
        return window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
    },[])

    return (
        <div className={`About ${width < 765 && 'AboutMobile'} ${width > 1024 && 'AboutPc'} ${width >= 766 && width <= 1366 && 'AboutIpad'} `}>
                {/* 简介 */}
                {
                    width < 765 ? 
                    <div className="About-introduction" name="introduction">
                        <div className="left">
                            <div className="left-top">
                                    <p className="about-large-title fontBlod">{t("about.about-introduction-title")}</p>
                                <div className="bg"></div>
                            </div>
                            <p className="desc1 about-content">{t("about.about-introduction-desc1")}</p>
                            <p className="desc2 about-content">{t("about.about-introduction-desc2")}</p>
                        </div>
                        <div className="right">
                            <div className="v-line"></div>
                            <div className="presence">
                                <p className="about-subtitle fontBlod">{t("about.about-introduction-subtitle")}</p>
                                <p className="about-content">{t("about.about-introduction-desc")}</p>
                            </div>
                        </div>
                    </div>
                    : 
                    <div className="About-introduction" name="introduction">
                        <div className="left">
                            <p className="about-large-title fontBlod">{t("about.about-introduction-title")}</p>
                            <div className="deline"></div>
                            <p className="desc1 about-content">{t("about.about-introduction-desc1")}</p>
                            <p className="desc2 about-content">{t("about.about-introduction-desc2")}</p>
                        </div>
                        <div className="right">
                            <div className="bg"></div>
                            <div className="v-line"></div>
                            <div className="presence">
                                <p className="about-subtitle title fontBlod">{t("about.about-introduction-subtitle")}</p>
                                <div className="deline"></div>
                                <p className="about-content">{t("about.about-introduction-desc")}</p>
                            </div>
                        </div>
                    </div>
                }
            <div className="layout-content ">


                {/* 价值观 */}
                <div className="About-values" name="beliefs">
                    <p className="about-title fontBlod">{t("about.about-beliefs-title")}</p>
                    <div className="line" />
                    <div className="content m-auto">
                        <div className="value-one value">
                            { width < 765 && <div className="imgs">
                                <img src={require('../assets/images/img/mobile/img-about-bg1-mobile.png')} />
                            </div> }
                            <div className="ValueItem">
                                <p className="value-title"><span className="about-color-title-left">{t("about.about-beliefs-subtitle-our")}</span> <span className="about-color-title-right">{t("about.about-beliefs-subtitle1")}</span></p>
                                {
                                    width < 766 && <div className="deline"></div>
                                }
                                <p className="about-content">{t("about.about-beliefs-desc1-li1")}</p>
                                <p className="about-content">{t("about.about-beliefs-desc1-li2")}</p>
                            </div>
                        </div>
                        <div className="value-two value">
                            { width < 765 && <div className="imgs">
                                <img src={require('../assets/images/img/mobile/img-about-bg2-mobile.png')} />
                            </div> }
                            <div className="ValueItem">
                                <span className="about-color-title-left">{t("about.about-beliefs-subtitle-our")}</span> <span className="about-color-title-right">{t("about.about-beliefs-subtitle2")}</span>
                                <div className="deline"></div>
                                <p className="about-content-title">{t("about.about-beliefs-desc2")}</p>
                                <div className="about-content">
                                    <p className="about-content-titleBox">
                                        <span className="about-content-word">{t("about.about-beliefs-desc2-title1")}</span>
                                        <span className="about-content-sub">{t("about.about-beliefs-desc2-subtitle1")}</span>
                                    </p>
                                    {t("about.about-beliefs-desc2-li1")}
                                </div>
                                <div className="about-content">
                                    <p className="about-content-titleBox">
                                        <span className="about-content-word">{t("about.about-beliefs-desc2-title2")}</span>
                                        <span className="about-content-sub">{t("about.about-beliefs-desc2-subtitle2")}</span>
                                    </p>
                                    {t("about.about-beliefs-desc2-li2")}
                                </div>
                                <div className="about-content">
                                    <p className="about-content-titleBox">
                                        <span className="about-content-word">{t("about.about-beliefs-desc2-title3")}</span>
                                        <span className="about-content-sub">{t("about.about-beliefs-desc2-subtitle3")}</span>
                                    </p>
                                    {t("about.about-beliefs-desc2-li3")}
                                </div>
                                <div className="about-content">
                                    <p className="about-content-titleBox">
                                        <span className="about-content-word">{t("about.about-beliefs-desc2-title4")}</span>
                                        <span className="about-content-sub">{t("about.about-beliefs-desc2-subtitle4")}</span>
                                    </p>
                                    {t("about.about-beliefs-desc2-li4")}
                                </div>
                                <div className="about-content">
                                    <p className="about-content-titleBox">
                                        <span className="about-content-word">{t("about.about-beliefs-desc2-title5")}</span>
                                        <span className="about-content-sub">{t("about.about-beliefs-desc2-subtitle5")}</span>
                                    </p>
                                    {t("about.about-beliefs-desc2-li5")}
                                </div>
                            </div>
                        </div>
                        <div className="value-three value">
                            { width < 765 && <div className="imgs">
                                <img src={require('../assets/images/img/mobile/img-about-bg3-mobile.png')} />
                            </div> }
                            <div className="ValueItem">
                                <span className="about-color-title-left">{t("about.about-beliefs-subtitle-our")}</span> <span className="about-color-title-right">{t("about.about-beliefs-subtitle3")}</span>
                                <div className="deline"></div>
                                <p className="about-content">{t("about.about-beliefs-desc3")}</p>
                            </div>
                        </div>
                        <div className="value-four value">
                            { width < 765 && <div className="imgs">
                                <img src={require('../assets/images/img/mobile/img-about-bg4-mobile.png')} />
                            </div> }
                            <div className="ValueItem">
                                <span className="about-color-title-left">{t("about.about-beliefs-subtitle-our")}</span> <span className="about-color-title-right">{t("about.about-beliefs-subtitle4")}</span>
                                <div className="deline"></div>
                                <p className="about-content">{t("about.about-beliefs-desc4-li1")}</p>
                                <p className="about-content">{t("about.about-beliefs-desc4-li2")}</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* 财富 */}
                <div className="About-wealth">
                    <p className="center-title about-subtitle fontBlod">{t(`about.about-beliefs-subtitle${5}`)}</p>
                    <div className="line"></div>
                    <div className="content m-auto">
                        {
                            WEALTH.map((e,i)=>(
                                <WealthItem key={i} title={e.title} img={e.img} content={e.content} />
                            ))
                        }
                    </div>
                </div>

            </div>
                {/* 团队 */}
                <div className="About-team" name="team">
                    <p className="about-title fontBlod">{t("about.about-team-title")}</p>
                    <div className="content m-auto">
                        {
                            TEAM.map((e, i) =>
                                <TeamItem key={i} icon={e.icon} name={e.name} job={e.job} desc={e.desc}></TeamItem>
                            )
                        }
                    </div>
                </div>
            <div className="About-investment">
                <p className="about-title fontBlod">{t("about.about-investment-title")}</p>
                <div className="line" />
                <div className="content">
                    <div className="content-title fontMedium">
                        {t("about.about-investment-subtitle")}
                    </div>
                    <div className="content-desc fontRegular">
                        {t("about.about-investment-desc")}
                    </div>
                    <div className="learn-more">
                        <a href={`/details?Investment_Philosophy`}>{t("others.learn")}<img src={require('../assets/images/img/img-right.png')} /></a>
                    </div>
                </div>
            </div>
            <div className="layout-content">
                {/* 新闻 */}
                <div className="About-news" name="news">
                    <p className="about-title fontBlod">{t("about.about-news-title")}</p>
                    <div className="line" />
                        <div className="content m-auto">
                            <p className="view-all">
                                <a href="list?news">{t("others.viewall")}<span>{'>>'}</span></a>
                            </p>
                            {
                                newsData.length > 0 && newsData.map((e, i) =>
                                    <NewsItem key={i} id={e.id} img={e.image} title={e.title} desc={e.desc} time={e.date} dataLang={dataLang}></NewsItem>
                                )
                            }
                        </div>
                </div>
            </div>
            </div>
    )
}