import "@/assets/styles/view-style/insights.scss";
import "@/assets/styles/component-style/insightItem.scss";
import "@/assets/styles/view-style/structured.scss"
import ReportItem from "../components/CustomItem/insight/ReportItem";
import { useEffect, useState } from "react";
import { getAMC } from "../request/api";
import { listProcessing } from "../utils/dataProcessing";
import {  Collapse,  } from "antd";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useViewport } from "../hooks/useViewport";
import ReactPlayer from 'react-player';


export default function AMC () {
    const location = useLocation()
    const {t} = useTranslation()
    const {width} = useViewport()
    const Panel = Collapse.Panel


    let [anchor,setAnchor] = useState(sessionStorage.getItem('anchorPoint'))


    let [lang,setLang] = useState(localStorage.getItem("btl.lang"))
    let [dataLang,setDataLang] = useState()
    let [amc1Data,setAmc1Data] = useState([]);
    let [amc2Data,setAmc2Data] = useState([]);
    let [activePlay,setActivePlay] = useState(1)



    const riskList = [
        {
            title: t("structuredProductRisk.subtitle1"),
            desc: t("structuredProductRisk.desc1")
        },
        {
            title: t("structuredProductRisk.subtitle2"),
            desc: t("structuredProductRisk.desc2")
        },
        {
            title: t("structuredProductRisk.subtitle3"),
            desc: t("structuredProductRisk.desc3")
        },
        {
            title: t("structuredProductRisk.subtitle4"),
            desc: t("structuredProductRisk.desc4")
        },
        {
            title: t("structuredProductRisk.subtitle5"),
            desc: t("structuredProductRisk.desc5")
        },
        {
            title: t("structuredProductRisk.subtitle6"),
            desc: t("structuredProductRisk.desc6")
        },
        {
            title: t("structuredProductRisk.subtitle7"),
            desc: t("structuredProductRisk.desc7")
        },
        {
            title: t("structuredProductRisk.subtitle8"),
            desc: t("structuredProductRisk.desc8")
        },
        {
            title: t("structuredProductRisk.subtitle9"),
            desc: t("structuredProductRisk.desc9")
        },
        {
            title: t("structuredProductRisk.subtitle10"),
            desc: t("structuredProductRisk.desc10")
        },
        {
            title: t("structuredProductRisk.subtitle11"),
            desc: t("structuredProductRisk.desc11")
        },
        {
            title: t("structuredProductRisk.subtitle12"),
            desc: t("structuredProductRisk.desc12")
        },
    ]



    const faqChange = (key) => {
        console.log(key);
    }
   

    const getAMC1Data = async () => {
        await getAMC({
            type: 1
        })
        .then((res) => {
            if (res.code === 0) {
                let data = listProcessing('Market_view',res.data.list)
                amc1Data = data;
                setAmc1Data([...amc1Data])
            }
        }).catch((err) => {

        });
    }

    const getAMC2Data = async () => {
        await getAMC({
            type: 2
        })
        .then((res) => {
            if (res.code === 0) {
                let data = listProcessing('Market_view',res.data.list)
                amc2Data = data;
                setAmc2Data([...amc2Data])
            }
        }).catch((err) => {

        });
    }

    const scrollToAnchor = (anchorName) => {
        let anchorElement = document.getElementsByClassName(`${anchorName}`)[0];
        anchorElement?.scrollIntoView({ behavior: 'smooth', block:'start' })
    }


    const settingIcon = (panelProps) => {
        if (panelProps.isActive) {
            return <img className="collapse-icon" src={require('@/assets/images/icon-less.png')} />
        }else {
            return <img className="collapse-icon" src={require('@/assets/images/icon-add.png')} />
        }
    }


    useEffect(() => {
        if (location.search !== "") {
            setTimeout(() => {
                scrollToAnchor(location.search.split("?")[1]);
            }, 200);
        }
    },[location,amc1Data,amc2Data])

    useEffect(()=>{
        if (lang === 'zh-TW') {
            dataLang = 'hk';
            setDataLang(dataLang)
        }else if (lang === 'zh-CN') {
            dataLang = 'cn';
            setDataLang(dataLang);
        }else{
            dataLang = 'en';
            setDataLang(dataLang)
        }
    },[lang])

    useEffect(()=>{
        window.addEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
        return window.removeEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
    },[])

    useEffect(()=>{
        let point = sessionStorage.getItem('anchorPoint');
        console.log(point);
        if (point) {
            setTimeout(()=>{
                scrollToAnchor(point)
            },200)
        }
    },[anchor])

    useEffect(()=>{
        window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
        return window.addEventListener('setanch',()=>{
            if (sessionStorage.getItem('anchorPoint')) {
                anchor = sessionStorage.getItem('anchorPoint')
                setAnchor(anchor)
            }
        })
    },[])

    useEffect(()=>{
        getAMC1Data();
        getAMC2Data();
    },[])


    return <div className={`Insights Structured ${width < 765 && 'InsightsMobile'} ${width > 1024 && 'InsightPc'} ${width >= 766 && width <= 1366 && 'InsightIpad'}`}>
    {/* 简介 */}
    <div className="layout-content">
        <div className="Insights-schema">
            <div className="content-b">
                <p className="insight-large-title fontMedium">
                    {t("AMCPage.title")}
                </p>
                <p className="insight-desc">We provide customers with leading market insight information to help customers invest in cross-class financial assets and diversify risks.We provide customers with leading market insight information to help customers invest in cross-class fo help customers invest in cross-class financial assets and diversify ri</p>
            </div>
        </div>
        {/* <div className="Insights-play">
            <div className="titleAndDesc">
                <div className="title">产品的基本玩法</div>
                <div className="desc">We provide customers with leading market insight information to help cust</div>
            </div>
            <div className="play-content">
                <div className="list">
                    <div 
                        className={`list-item ${activePlay === 1 && 'list-item-active'}`}
                        onClick={()=>setActivePlay(1)}
                    >
                        <div className="icon">FCN</div>
                        <div className="text">定息票据</div>
                    </div>
                    <div 
                        className={`list-item ${activePlay === 2 && 'list-item-active'}`}
                        onClick={()=>setActivePlay(2)}
                    >
                        <div className="icon">ELN</div>
                        <div className="text">股票挂钩票据</div>
                    </div>
                    <div 
                        className={`list-item ${activePlay === 3 && 'list-item-active'}`}
                        onClick={()=>setActivePlay(3)}
                    >
                        <div className="icon">BEN</div>
                        <div className="text">持有人权益票据</div>
                    </div>
                    <div 
                        className={`list-item ${activePlay === 4 && 'list-item-active'}`}
                        onClick={()=>setActivePlay(4)}
                    >
                        <div className="icon">···</div>
                        <div className="text">更多</div>
                    </div>
                </div>
                <div className="video-content">
                    <ReactPlayer 
                        url='http://120.198.17.24:8089//uploads/file/app/2023/12/-_001_-_.mp4' 
                        controls 
                        width="11.71rem"
                        height="6.59rem"
                    />
                </div>
            </div>
        </div> */}
        {/* 报告 */}
        {
        <div className="Insights-view insights-Market_review insights-item TrackingNotes">
            <div className="content m-auto">
                <div className="content-t">
                    <p className="insight-title fontMedium">{t("AMCPage.subtitle2")}</p>
                    <div className="line"></div>
                    <a className="insight-content" href="list?BAOI">{t("others.viewall")}</a>
                </div>
                <div className="content-b">
                    {
                        amc2Data.length > 0 && amc2Data.map((e, i) =>
                            <ReportItem key={i} lang={dataLang} bg={e.image} title={e.title} desc={e.desc} file={e.file}></ReportItem>
                        )
                    }
                    {
                        (amc2Data.length === 1 || amc2Data.length === 3) ? <div className="ReportItem report-empty-item">
                            <p>{t("others.comming-soon")}</p>
                        </div> : ''
                    }
                </div>
            </div>
        </div> }

        {/* 观点 */}
        {
        <div className="Insights-view insights-Market_review insights-item TrackingCertificate">
            <div className="content m-auto">
                <div className="content-t">
                    <p className="insight-title fontMedium">{t("AMCPage.subtitle1")}</p>
                    <div className="line"></div>
                    <a className="insight-content" href="list?AWGOI">{t("others.viewall")}</a>
                </div>
                <div className="content-b">
                    {
                        amc1Data.length > 0 && amc1Data.map((e, i) =>
                            <ReportItem key={i} lang={dataLang} bg={e.image} title={e.title} desc={e.desc} file={e.file}></ReportItem>
                        )
                    }
                    {
                        (amc1Data.length === 1 || amc1Data.length === 3) ? <div className="ReportItem report-empty-item">
                            <p>{t("others.comming-soon")}</p>
                        </div> : ''
                    }
                </div>
            </div>
        </div> }
        
        {/* <div className="Insights-faq">
            <div className="faq-title">
                FAQ
            </div>
            <Collapse 
                expandIconPosition="end"
                onChange={faqChange}
                expandIcon={(panelProps)=>settingIcon(panelProps)}
                ghost={true}
            >
                <Panel header={<div><img className="question-icon" src={require('@/assets/images/icon-question.png')} /><span className="text">常见问题</span></div>} key="1" style={{borderRadius: '0px'}}>
                    <p>People who have a student VISA or Working VISA. Please contact us if you have other VISA.</p>
                </Panel>
                <Panel header={<div><img className="question-icon" src={require('@/assets/images/icon-question.png')} /><span className="text">常见问题</span></div>} key="2">
                    <p>People who have a student VISA or Working VISA. Please contact us if you have other VISA.</p>
                </Panel>
                <Panel header={<div><img className="question-icon" src={require('@/assets/images/icon-question.png')} /><span className="text">常见问题</span></div>} key="3">
                    <p>People who have a student VISA or Working VISA. Please contact us if you have other VISA.</p>
                </Panel>
                <Panel header={<div><img className="question-icon" src={require('@/assets/images/icon-question.png')} /><span className="text">常见问题</span></div>} key="4" style={{borderRadius: '0px'}}>
                    <p>People who have a student VISA or Working VISA. Please contact us if you have other VISA.</p>
                </Panel>
                <Panel header={<div><img className="question-icon" src={require('@/assets/images/icon-question.png')} /><span className="text">常见问题</span></div>} key="5">
                    <p>People who have a student VISA or Working VISA. Please contact us if you have other VISA.</p>
                </Panel>
                <Panel header={<div><img className="question-icon" src={require('@/assets/images/icon-question.png')} /><span className="text">常见问题</span></div>} key="6">
                    <p>People who have a student VISA or Working VISA. Please contact us if you have other VISA.</p>
                </Panel>
            </Collapse>
        </div> */}
        <div className="Insights-risk ProductRisk">
            <div className="risk-title fontBlod">{t("structuredProductRisk.title")}</div>
            <div className="risk-content">
                {
                    riskList.map((e,i)=>(
                        <div className="list-item" key={i}>
                            <div className="item-title fontMedium">{e.title}</div>
                            <div className="item-desc fontNormal">{e.desc}</div>
                        </div>
                    ))
                }
            </div>
        </div>
    </div>
    </div>
}

