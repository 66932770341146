import "@/assets/styles/view-style/details.scss"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useViewport } from "../hooks/useViewport"
import { Divider } from "antd"
import AssetManagementItem from "../components/CustomItem/services/AssetManagementItem"


export default function Details () {
    const location = useLocation()
    const { t } = useTranslation()
    const {width} = useViewport()

    let [lang,setLang] = useState(localStorage.getItem("btl.lang"))
    let [type,setType] = useState()
    let [activeBasicTag,setActiveBasicTag] = useState()

    const VALUE = [
        {
            type: "Stock_Connect",
            title: t("services.services-business-subtitle2"),
            desc: [
                t("services.services-business-desc2-li1"),
                t("services.services-business-desc2-li2"),
                t("services.services-business-desc2-li3"),
                t("services.services-business-desc2-li4"),
                t("services.services-business-desc2-li5"),
                t("services.services-business-desc2-li6"),
            ]
        },
        {
            type: "Personal_Data_Policy",
            title: t("personal-data-policy.title"),
            desc: [
                t("personal-data-policy.desc"),
                t("personal-data-policy.content1"),
                t("personal-data-policy.content1-con1"),
                t("personal-data-policy.content1-con2"),
                t("personal-data-policy.content1-con3"),
                t("personal-data-policy.content1-con4"),
                t("personal-data-policy.content1-con5"),
                t("personal-data-policy.content2"),
                t("personal-data-policy.content3"),
                t("personal-data-policy.content4"),
                t("personal-data-policy.content5"),
                t("personal-data-policy.content6"),
                t("personal-data-policy.content7"),

            ]
        },
        {
            type: "Disclaimer",
            title: t("disclaimer.title"),
            desc: [
                t("disclaimer.subtitle"),
                t("disclaimer.desc"),
                t("disclaimer.content1"),
                t("disclaimer.content2"),
                t("disclaimer.content3"),
                t("disclaimer.content4"),
                t("disclaimer.content5"),
                t("disclaimer.content6"),
                t("disclaimer.content7"),
                t("disclaimer.content8"),
                t("disclaimer.content9"),
                t("disclaimer.content10"),
                t("disclaimer.content11"),
            ]
        }
    ]

    const familyAdvantage = [
        {
            title: t("Wealth.content2-title1"),
        },
        {
            title: t("Wealth.content2-title2"),
        },
        {
            title: t("Wealth.content2-title3"),
        },
        {
            title: t("Wealth.content2-title4"),
        },
    ]

    const InvestmentPhilosophyList1 = [
        {
            title: t("InvestmentPhilosophy.content1-title1"),
            desc: t("InvestmentPhilosophy.content1-desc1")
        },
        {
            title: t("InvestmentPhilosophy.content1-title2"),
            desc: t("InvestmentPhilosophy.content1-desc2")
        },
        {
            title: t("InvestmentPhilosophy.content1-title3"),
            desc: t("InvestmentPhilosophy.content1-desc3")
        },
        {
            title: t("InvestmentPhilosophy.content1-title4"),
            desc: t("InvestmentPhilosophy.content1-desc4")
        },
    ]

    const InvestmentPhilosophyList2 = [
        {
            title: t("InvestmentPhilosophy.content2-title1"),
            desc: t("InvestmentPhilosophy.content2-desc1")
        },
        {
            title: t("InvestmentPhilosophy.content2-title2"),
            desc: t("InvestmentPhilosophy.content2-desc2")
        },
        {
            title: t("InvestmentPhilosophy.content2-title3"),
            desc: t("InvestmentPhilosophy.content2-desc3")
        },
        {
            title: t("InvestmentPhilosophy.content2-title4"),
            desc: t("InvestmentPhilosophy.content2-desc4")
        },
        {
            title: t("InvestmentPhilosophy.content2-title5"),
            desc: t("InvestmentPhilosophy.content2-desc5")
        },
    ]

    const ASSETMANAGEMENTS = [
        {
            title: t("Wealth.content2-title1"),
        },
        {
            title: t("Wealth.content2-title2"),
        },
        {
            title: t("Wealth.content2-title3"),
            content: t("Wealth.content2-desc3")
        },
        {
            title: t("Wealth.content2-title4"),
        },
    ]

    const addClassName = (i) => {
        if (activeBasicTag === i) {
            setActiveBasicTag('')
        }else{
            setActiveBasicTag(i)
        }
    }

    useEffect(()=>{
        type = location.search.split('?')[1];
        setType(type)
    },[location])

    useEffect(()=>{
        window.addEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
        return window.removeEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
    },[])

    return <div className={`details ${width < 765 && 'detailsMobile'} ${width >= 766 && width <= 1366 && 'detailsIpad'}`}>
        <div className="layout-content">
            <div className={`details-banner ${type === 'Personal_Data_Policy' && 'policy'} ${type === 'Disclaimer' && 'policy'}`}>
                {/* <img src={require(`@/assets/images/bg/bg-about-news${width < 481 ? '-mobile' : ''}.png`)} /> */}
                
                {
                    type === 'Investment_Advisory_Mandates' && <img src={require('@/assets/images/img/assetManager/advisoryBanner.png')} />
                }
                {
                    type === 'Discretionary_Mandates' && <img src={require('@/assets/images/img/assetManager/DiscretionaryBanner.png')} />
                }
                {
                    type === 'External_Asset_Manager' && <img src={require('@/assets/images/img/assetManager/assetBanner.png')} />
                }
                {
                    type === 'Other_Investment_Services' && <img src={require('@/assets/images/img/assetManager/InvestmentBanner.png')} />
                }
                {
                    type === 'Alternative_Investment_Funds' && <img src={require('@/assets/images/img/assetManager/otherBanner.png')} />
                }
                {
                    type === 'AMC' && <img src={require('@/assets/images/img/assetManager/AMCBanner.png')} />
                }
                {
                    type === 'HK_Market' && <img src={require('@/assets/images/bg/details-bg.png')} />
                }
                {
                    type === 'Stock_Connect' && <img src={require('@/assets/images/img/assetManager/stockBanner.png')} />
                }
                {
                    type === 'Global_Market' && <img src={require('@/assets/images/img/assetManager/globalBanner.png')} />
                }
                {
                    type === 'Futures_Options' && <img src={require('@/assets/images/img/assetManager/futuresBanner.png')} />
                }
                {
                    type === 'Fixed_Income' && <img src={require('@/assets/images/img/assetManager/fixedBanner.png')} />
                }
                {
                    type === 'Structured_Products' && <img src={require('@/assets/images/img/assetManager/ structuredBanner.png')} />
                }
                {
                    type === 'MUTUAL_FUND' && <img src={require('@/assets/images/img/assetManager/mutual-img.png')} />
                }
                {
                    type === 'OPTION' && <img src={require('@/assets/images/img/assetManager/option-img.png')} />
                }
                {
                    type === 'Fixed' && <img src={require('@/assets/images/img/assetManager/fixed-img.png')} />
                }
                {
                    type === 'Bond' && <img src={require('@/assets/images/img/assetManager/bond-img.png')} />
                }
                {
                    (type === 'Wealth_Inheritance' || type === 'Investment_Philosophy') && <img src={require('@/assets/images/bg/details-bg.png')} />
                }
                {/* {
                    width < 481 && <img className="banner-logo" src={require('@/assets/images/bg/bg-about-news-logo.png')} />
                } */}
            </div>
            {
                VALUE.map((e,i)=>(
                    e.type === type && type !== 'External_Asset_Manager' ?<div className="details-content" key={i}>
                        <div className="content-title">
                            <h5>{e.title}</h5>
                        </div>
                        <div className={`content-desc ${type==='AMC' && 'AMC-desc'}`}>
                            {
                                e.desc.map((ele,ind)=>(
                                    <p key={ind}>{ele}</p>
                                ))
                            }
                        </div>
                    </div> : ''
                ))
            }
            {
                type === 'External_Asset_Manager' && <div className="Asset-manager">
                    <div className="detail-title">
                        <p>{t("external-asset-manager.title")}</p>
                    </div>
                    <div className="detail-subtitle">
                        <div className="subtitle-item">
                            <img src={require('../assets/images/img/assetManager/asset-jiantou.png')} />
                            <span>{t("external-asset-manager.content-title1")}</span>
                        </div>
                        <div className="subtitle-item">
                            <img src={require('../assets/images/img/assetManager/asset-jiantou.png')} />
                            <span>{t("external-asset-manager.content-title2")}</span>
                        </div>
                    </div>
                    <div className="detail-content">
                        <div className="content-item">
                            <div className="item-left m130">
                                <img src={require(`../assets/images/img/pc_one_small.png`)} />
                                <p className="item-title fontMedium fontBlod">{t("external-asset-manager.content-desc-title1")}</p>
                                <p className="item-content">{t("external-asset-manager.content-desc-content1")}</p>
                            </div>
                            <div className="item-right">
                                <img src={require(`../assets/images/img/assetManager/bg1.png`)} />
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="item-right m130">
                                <img src={require(`../assets/images/img/assetManager/bg2.png`)} />
                            </div>
                            <div className="item-left ">
                                <img src={require(`../assets/images/img/pc_two_small.png`)} />
                                <p className="item-title fontMedium fontBlod">{t("external-asset-manager.content-desc-title2")}</p>
                                <p className="item-content">{t("external-asset-manager.content-desc-content2")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="item-left m130">
                                <img src={require(`../assets/images/img/pc_three_small.png`)} />
                                <p className="item-title fontMedium fontBlod">{t("external-asset-manager.content-desc-title3")}</p>
                                <p className="item-content">{t("external-asset-manager.content-desc-content3")}</p>
                            </div>
                            <div className="item-right">
                                <img src={require(`../assets/images/img/assetManager/bg3.png`)} />
                            </div>
                        </div>
                    </div>
                    <div className="detail-footer">
                        <p>{t("external-asset-manager.content-title3")}</p>
                        <p>{t("external-asset-manager.content-title4")}</p>
                    </div>
                </div>
            }
            {
                type === 'Discretionary_Mandates' && <div className="Discretionary_Mandates">
                    <div className="detail-title">
                        <p>{t("discretionary-mandates.title")}</p>
                    </div>
                    <div className="detail-subtitle">
                        <div className="subtitle-item">
                            <img src={require('../assets/images/img/assetManager/asset-jiantou.png')} />
                            <span>{t("discretionary-mandates.discretionary-title1")}</span>
                        </div>
                        <div className="subtitle-item">
                            <img src={require('../assets/images/img/assetManager/asset-jiantou.png')} />
                            <span>{t("discretionary-mandates.discretionary-title2")}</span>
                        </div>
                        <div className="subtitle-desc">
                            <span>{t("discretionary-mandates.discretionary-subtitle2")}</span>
                        </div>
                        <div className="subtitle-item">
                            <img src={require('../assets/images/img/assetManager/asset-jiantou.png')} />
                            <span>{t("discretionary-mandates.discretionary-title3")}</span>
                        </div>
                    </div>
                    <div className="detail-desc">
                        <p className="desc-item">{t("discretionary-mandates.discretionary-desc1")}</p>
                        <p className="desc-item">{t("discretionary-mandates.discretionary-desc2")}</p>
                        <p className="desc-item">{t("discretionary-mandates.discretionary-desc3")}</p>
                        <p className="desc-item">{t("discretionary-mandates.discretionary-desc4")}</p>
                    </div>
                    <div className="detail-content">
                        <div className="content-title fontMedium">{t("discretionary-mandates.discretionary-desc5")}</div>
                        <div className="content-item">
                            <div className="item-left m130">
                                <img src={require(`../assets/images/img/pc_one_small.png`)} />
                                <p className="item-title fontMedium fontBlod">{t("discretionary-mandates.discretionary-li-title1")}</p>
                                <p className="item-content">{t("discretionary-mandates.discretionary-li-content1")}</p>
                                <p className="item-desc fontRegular">{t("discretionary-mandates.discretionary-li-desc1")}</p>
                            </div>
                            <div className="item-right">
                                <img src={require(`../assets/images/img/assetManager/DiscretionaryImg1.png`)} />
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="item-right m130">
                                <img src={require(`../assets/images/img/assetManager/DiscretionaryImg2.png`)} />
                            </div>
                            <div className="item-left ">
                                <img src={require(`../assets/images/img/pc_two_small.png`)} />
                                <p className="item-title fontMedium fontBlod">{t("discretionary-mandates.discretionary-li-title2")}</p>
                                <p className="item-content">{t("discretionary-mandates.discretionary-li-content2")}</p>
                                <p className="item-desc fontRegular">{t("discretionary-mandates.discretionary-li-desc2")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="item-left m130">
                                <img src={require(`../assets/images/img/pc_three_small.png`)} />
                                <p className="item-title fontMedium fontBlod">{t("discretionary-mandates.discretionary-li-title3")}</p>
                                <p className="item-content">{t("discretionary-mandates.discretionary-li-content3")}</p>
                                <p className="item-desc fontRegular">{t("discretionary-mandates.discretionary-li-desc3")}</p>
                            </div>
                            <div className="item-right">
                                <img src={require(`../assets/images/img/assetManager/DiscretionaryImg3.png`)} />
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="item-right m130">
                                <img src={require(`../assets/images/img/assetManager/DiscretionaryImg4.png`)} />
                            </div>
                            <div className="item-left ">
                                <img src={require(`../assets/images/img/pc_four_small.png`)} />
                                <p className="item-title fontMedium fontBlod">{t("discretionary-mandates.discretionary-li-title4")}</p>
                                <p className="item-content">{t("discretionary-mandates.discretionary-li-content4")}</p>
                                <p className="item-desc fontRegular">{t("discretionary-mandates.discretionary-li-desc4")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="item-left m130">
                                <img src={require(`../assets/images/img/pc_five_small.png`)} />
                                <p className="item-title fontMedium fontBlod">{t("discretionary-mandates.discretionary-li-title5")}</p>
                                <p className="item-content">{t("discretionary-mandates.discretionary-li-content5")}</p>
                                <p className="item-desc fontRegular">{t("discretionary-mandates.discretionary-li-desc5")}</p>
                            </div>
                            <div className="item-right">
                                <img src={require(`../assets/images/img/assetManager/DiscretionaryImg5.png`)} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                type === 'Investment_Advisory_Mandates' && <div className="Investment_Advisory_Mandates">
                    <div className="detail-title">
                        <p>{t("services.services-plan-title1")}</p>
                    </div>
                    <div className="detail-content">
                        <p className="content-item">{t("services.services-plan-desc1")}</p>
                        <div className="content-image">
                            <img src={require('@/assets/images/img/assetManager/advisoryImg1.png')} />
                        </div>
                    </div>
                </div>
            }
            {
                type === 'HK_Market' && <div className="HK_Market">
                    <div className="detail-title">
                        <p>{t("services.services-business-subtitle1")}</p>
                    </div>
                    <div className="detail-subtitle">
                        <div className="subtitle-item">
                            <img src={require('../assets/images/img/assetManager/asset-jiantou.png')} />
                            <span>{t("services.services-business-desc1-li1")}</span>
                        </div>
                    </div>
                    <div className="detail-content">
                        <div className="content-item">
                            <div className="item-img">
                                <img src={require('@/assets/images/img/assetManager/HKImg1.png')} />
                            </div>
                            <div className="item-text">
                                <p className="text-content">{t("services.services-business-desc1-li2")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="item-img">
                                <img src={require('@/assets/images/img/assetManager/HKImg2.png')} />
                            </div>
                            <div className="item-text">
                                <p className="text-content">{t("services.services-business-desc1-li3")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="item-img">
                                <img src={require('@/assets/images/img/assetManager/HKImg3.png')} />
                            </div>
                        </div>
                        <div className="content-footer content-item">
                            <p className="item-text">{t("services.services-business-desc1-li4")}</p>
                        </div>
                    </div>
                </div>
            }
            {
                type === 'AMC' && <div className="AMC">
                    <div className="detail-title">
                        <p>{t("AMC.title")}</p>
                    </div>
                    <div className="detail-subtitle">
                        <p className="subtitle-text">{t("AMC.content-title1")}</p>
                    </div>
                    <div className="detail-content">
                        <div className="content-item content-img">
                            <div className="item-img">
                                <img src={require(`@/assets/images/img/assetManager/amcImg-${lang}.png`)} />
                            </div>
                            <div className="item-text">
                                <p className="text">{t("AMC.content-title2")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <div className="content-title">
                                <p className="title">{t("AMC.content-title3")}</p>
                                <p className="subtitle">{t("AMC.content-title4")}</p>
                            </div>
                            <div className="content">
                                <ul className="content-list"> 
                                    <li>
                                        <div className="round"></div>
                                        <span>{t("AMC.content-desc1")}</span>
                                    </li>
                                    <li>
                                        <div className="round"></div>
                                        <span>{t("AMC.content-desc2")}</span>
                                    </li>
                                    <li>
                                        <div className="round"></div>
                                        <span>{t("AMC.content-desc3")}</span>
                                    </li>
                                    <li>
                                        <div className="round"></div>
                                        <span>{t("AMC.content-desc4")}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                type === 'Global_Market' && <div className="Global_Market">
                    <div className="detail-title">
                        <p>{t("services.services-business-subtitle3")}</p>
                    </div>
                    <div className="detail-content">
                        <div className="content-item">
                            <p className="item-text">{t("services.services-business-desc3-li1")}</p>
                        </div>
                        <div className="content-item">
                            <img src={require('@/assets/images/img/assetManager/globalImg.png')} />
                        </div>
                        <div className="content-item">
                            <p className="item-text">{t("services.services-business-desc3-li2")}</p>
                        </div>
                    </div>
                </div>
            }
            {
                type === 'Futures_Options' && <div className="Futures_Options">
                    <div className="detail-title">
                        <p>{t("services.services-business-subtitle4")}</p>
                    </div>
                    <div className="detail-content">
                        <p className="content-title fontBlod">{t("services.services-business-desc4-title")}</p>
                        <div className="content">
                            <p className="text">{t("services.services-business-desc4-li1")}</p>
                            <div className="img">
                                <img src={require('@/assets/images/img/assetManager/futuresImg1.png')} />
                            </div>
                            <p className="text">{t("services.services-business-desc4-li2")}</p>
                            <ul className="content-list"> 
                                <li>
                                    <div className="round"></div>
                                    <span>{t("services.services-business-desc4-li3")}</span>
                                </li>
                                <li>
                                    <div className="round"></div>
                                    <span>{t("services.services-business-desc4-li4")}</span>
                                </li>
                                <li>
                                    <div className="round"></div>
                                    <span>{t("services.services-business-desc4-li5")}</span>
                                </li>
                                <li>
                                    <div className="round"></div>
                                    <span>{t("services.services-business-desc4-li6")}</span>
                                </li>
                                <li>
                                    <div className="round"></div>
                                    <span>{t("services.services-business-desc4-li7")}</span>
                                </li>
                            </ul>
                            <p className="text">{t("services.services-business-desc4-li8")}</p>
                        </div>
                        <p className="content-title fontBlod">{t("services.services-business-desc4-title1")}</p>
                        <div className="content">
                            <p className="text">{t("services.services-business-desc4-li11")}</p>
                            <div className="img">
                                <img src={require('@/assets/images/img/assetManager/futuresImg2.png')} />
                            </div>
                            <p className="text">{t("services.services-business-desc4-li12")}</p>
                            <p className="text m20">{t("services.services-business-desc4-li13")}</p>
                            <ul className="content-list"> 
                                <li>
                                    <div className="round"></div>
                                    <span>{t("services.services-business-desc4-li14")}</span>
                                </li>
                                <li>
                                    <div className="round"></div>
                                    <span>{t("services.services-business-desc4-li15")}</span>
                                </li>
                                <li>
                                    <div className="round"></div>
                                    <span>{t("services.services-business-desc4-li16")}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            }
            {
                type === 'Structured_Products' && <div className="Structured_Products">
                    <div className="detail-title">
                        <p>{t("services.services-business-subtitle5")}</p>
                    </div>
                    <div className="detail-subtitle fontBlod">
                        <p>{t("services.services-business-desc5-li1")}</p>
                    </div>
                    <div className="detail-content">
                        <p className="text">{t("services.services-business-desc5-li2")}</p>
                        <p className="text">{t("services.services-business-desc5-li3")}</p>
                        <ul className="content-list"> 
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc5-li4")}</span>
                            </li>
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc5-li5")}</span>
                            </li>
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc5-li6")}</span>
                            </li>
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc5-li7")}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            }
            {
                type === 'Fixed_Income' && <div className="Structured_Products Fixed_Income">
                    <div className="detail-title">
                        <p>{t("services.services-business-subtitle6")}</p>
                    </div>
                    <div className="detail-subtitle fontBlod">
                        <p>{t("services.services-business-desc6-li1")}</p>
                    </div>
                    <div className="detail-content">
                        <p className="text">{t("services.services-business-desc6-li1-2")}</p>
                        <p className="text">{t("services.services-business-desc6-li2")}</p>
                        <p className="text">{t("services.services-business-desc6-li3")}</p>
                        <p className="text">{t("services.services-business-desc6-li4")}</p>
                        <ul className="content-list"> 
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc6-li5")}</span>
                            </li>
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc6-li6")}</span>
                            </li>
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc6-li7")}</span>
                            </li>
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc6-li8")}</span>
                            </li>
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc6-li9")}</span>
                            </li>
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc6-li10")}</span>
                            </li>
                            <li>
                                <div className="round"></div>
                                <span>{t("services.services-business-desc6-li11")}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            }
            {
                type === 'Other_Investment_Services' && <div className="Other_Investment_Services">
                    <div className="detail-title">
                        <p>{t("services.services-plan-title4")}</p>
                    </div>
                    <div className="detail-content">
                        <p className="text">{t("services.services-plan-desc4-li1")}</p>
                        <div className="img">
                            <img src={require('@/assets/images/img/assetManager/InvestmentImg.png')} />
                        </div>
                        <p className="text fontMedium">{t("services.services-plan-desc4-li1-2")}</p>
                        <div className="item">
                            <div className="round"></div>
                            <p className="text">{t("services.services-plan-desc4-li2")}</p>
                        </div>
                        <div className="item">
                            <div className="round"></div>
                            <p className="text">{t("services.services-plan-desc4-li3")}</p>
                        </div>
                    </div>
                </div>
            }
            {
                type === 'Alternative_Investment_Funds' && <div className="Alternative_Investment_Funds">
                    <div className="detail-title">
                        <p>{t("services.services-investment-subtitle1")}</p>
                    </div>
                    <div className="detail-content">
                        <div className="item">
                            <div className="img">
                                <img src={require('@/assets/images/img/pc_num1.png')} />
                            </div>
                            <p className="text">{t("services.services-investment-desc1-li1")}</p>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={require('@/assets/images/img/pc_num2.png')} />
                            </div>
                            <p className="text">{t("services.services-investment-desc1-li2")}</p>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={require('@/assets/images/img/pc_num3.png')} />
                            </div>
                            <p className="text">{t("services.services-investment-desc1-li3")}</p>
                        </div>
                        <div className="item">
                            <div className="img">
                                <img src={require('@/assets/images/img/pc_num4.png')} />
                            </div>
                            <p className="text">{t("services.services-investment-desc1-li4")}</p>
                        </div>
                    </div>
                </div>
            }
            {
                type === "MUTUAL_FUND" && <div className="MUTUAL_FUND education">
                    <div className="detail-title">
                        <p>{t("MUTUAL_FUND.title")}</p>
                    </div>
                    <div className="detail-content">
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("MUTUAL_FUND.subtitle1")}</p>
                            <div className="item-content">
                                <p className="content-li">{t("MUTUAL_FUND.content1")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("MUTUAL_FUND.subtitle2")}</p>
                            <div className="item-content item-table">
                                <div>
                                    <div className="content-item">
                                        <p className="content-item-title fontMedium fontMedium">{t("MUTUAL_FUND.content2-title1")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content2-desc1")}</p>
                                    </div>
                                    <div className="content-item">
                                        <p className="content-item-title fontMedium fontMedium">{t("MUTUAL_FUND.content2-title2")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content2-desc2")}</p>
                                    </div>
                                    <div className="content-item">
                                        <p className="content-item-title fontMedium fontMedium">{t("MUTUAL_FUND.content2-title3")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content2-desc3")}</p>
                                    </div>
                                    <div className="content-item">
                                        <p className="content-item-title fontMedium fontMedium">{t("MUTUAL_FUND.content2-title4")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content2-desc4")}</p>
                                    </div>
                                </div>
                                <p className="content-item">{t("MUTUAL_FUND.content2")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("MUTUAL_FUND.subtitle3")}</p>
                            <div className="item-content">
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("MUTUAL_FUND.content3-title1")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("MUTUAL_FUND.content3-desc1-title1")}</p>
                                    <p className="item-desc fontRegular">{t("MUTUAL_FUND.content3-desc1-content1")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("MUTUAL_FUND.content3-desc1-title2")}</p>
                                    <p className="item-desc fontRegular">{t("MUTUAL_FUND.content3-desc1-content2")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("MUTUAL_FUND.content3-desc1-title3")}</p>
                                    <p className="item-desc fontRegular">{t("MUTUAL_FUND.content3-desc1-content3")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("MUTUAL_FUND.content3-desc1-title4")}</p>
                                    <p className="item-desc fontRegular">{t("MUTUAL_FUND.content3-desc1-content4")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("MUTUAL_FUND.content3-desc1-title5")}</p>
                                    <p className="item-desc fontRegular">{t("MUTUAL_FUND.content3-desc1-content5")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("MUTUAL_FUND.content3-desc1-title6")}</p>
                                    <p className="item-desc fontRegular">{t("MUTUAL_FUND.content3-desc1-content6")}</p>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("MUTUAL_FUND.content3-title2")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("MUTUAL_FUND.content3-desc2-title1")}</p>
                                    <p className="item-desc fontRegular">{t("MUTUAL_FUND.content3-desc2-desc1")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("MUTUAL_FUND.content3-desc2-title2")}</p>
                                    <p className="item-desc fontRegular">{t("MUTUAL_FUND.content3-desc2-desc2")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("MUTUAL_FUND.content3-desc2-title3")}</p>
                                    <p className="item-desc fontRegular">{t("MUTUAL_FUND.content3-desc2-desc3")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("MUTUAL_FUND.subtitle4")}</p>
                            <div className="item-content">
                                <p className="content-li">{t("MUTUAL_FUND.content4-desc1")}</p>
                                <p className="content-li">{t("MUTUAL_FUND.content4-desc2")}</p>
                                <div className="content-img">
                                    <img src={require(`../assets/images/img/assetManager/mutual-img-${lang}.png`)} />
                                </div>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("MUTUAL_FUND.subtitle5")}</p>
                            <div className="item-content item-table item-consideration">
                                <p className="content-li">{t("MUTUAL_FUND.content5-desc1")}</p>
                                <div>
                                    <div className="content-item">
                                        <p className="content-item-title fontMedium fontMedium">{t("MUTUAL_FUND.content5-desc2-title1")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content5-desc2-desc1")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content5-desc2-desc2")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content5-desc2-desc3")}</p>
                                    </div>
                                    <div className="content-item">
                                        <p className="content-item-title fontMedium fontMedium">{t("MUTUAL_FUND.content5-desc2-title2")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content5-desc2-desc4")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content5-desc2-desc5")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content5-desc2-desc6")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content5-desc2-desc7")}</p>
                                    </div>
                                    <div className="content-item">
                                    <p className="content-item-title fontMedium fontMedium">{t("MUTUAL_FUND.content5-desc2-title3")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content5-desc2-desc8")}</p>
                                        <p className="content-item-desc fontRegular fontRegular">{t("MUTUAL_FUND.content5-desc2-desc9")}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("MUTUAL_FUND.subtitle6")}</p>
                            <div className="item-content">
                                <p className="content-li">{t("MUTUAL_FUND.content6")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("MUTUAL_FUND.subtitle7")}</p>
                            <div className="item-content">
                                <p className="content-li">{t("MUTUAL_FUND.content7")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                type === "Bond" && <div className="Bond education">
                    <div className="detail-title">
                        <p>{t("Bond.title")}</p>
                    </div>
                    <div className="detail-content">
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("Bond.subtitle1")}</p>
                            <div className="item-content">
                                <p className="content-li">{t("Bond.content1")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("Bond.subtitle2")}</p>
                            <div className="item-content">
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("Bond.content2-desc1")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("Bond.content2-desc2")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("Bond.content2-desc3")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("Bond.content2-desc4")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("Bond.content2-desc5")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("Bond.content2-desc6")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("Bond.content2-desc7")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("Bond.content2-desc8")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("Bond.content2-desc9")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("Bond.subtitle3")}</p>
                            <div className="item-content">
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("Bond.content3-title1")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc1-title1")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc1-content1")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc1-title2")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc1-content2")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc1-title3")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc1-content3")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc1-title4")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc1-content4")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc1-title5")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc1-content5")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc1-title6")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc1-content6")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc1-title7")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc1-content7")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc1-title8")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc1-content8")}</p>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("Bond.content3-title2")}</p>
                                    <p className="content-li">{t("Bond.content3-desc2")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc2-title1")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc2-content1")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc2-title2")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc2-content2")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("Bond.content3-desc2-title3")}</p>
                                    <p className="item-desc fontRegular">{t("Bond.content3-desc2-content3")}</p>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("Bond.content3-title3")}</p>
                                    <div className="scene-content">
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("Bond.content3-desc3-title1")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("Bond.content3-desc3-content1")}</p>
                                            </div>
                                        </div>
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("Bond.content3-desc3-title2")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("Bond.content3-desc3-content2")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                type === "OPTION" && <div className="OPTION education">
                    <div className="detail-title">
                        <p>{t("OPTION.title")}</p>
                    </div>
                    <div className="detail-content">
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("OPTION.subtitle1")}</p>
                            <div className="item-content">
                                <p className="content-li">{t("OPTION.content1")}</p>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("OPTION.subtitle2")}</p>
                            <div className="item-content">
                                <p className="content-li">{t("OPTION.content2")}</p>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("OPTION.content2-desc1")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("OPTION.content2-desc2")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("OPTION.content2-desc3")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("OPTION.content2-desc4")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("OPTION.content2-desc5")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-desc fontRegular fontRegular">{t("OPTION.content2-desc6")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("OPTION.subtitle3")}</p>
                            <div className="item-content">
                                <div className="bcwhite">
                                    <p>{t("OPTION.content3-desc1")}</p>
                                </div>
                                <div className="bcwhite">
                                    <p>{t("OPTION.content3-desc2")}</p>
                                </div>
                                <div className="option-img">
                                    <img src={require(`../assets/images/img/assetManager/option-tree-${lang}.png`)} />
                                </div>
                                <div className="option-imglist">
                                    <div className="option-imgli">
                                        <img src={require(`../assets/images/img/assetManager/option-table1-${lang}.png`)} />
                                    </div>
                                    <div className="option-imgli">
                                        <img src={require(`../assets/images/img/assetManager/option-table2-${lang}.png`)} />
                                    </div>
                                    <div className="option-imgli">
                                        <img src={require(`../assets/images/img/assetManager/option-table3-${lang}.png`)} />
                                    </div>
                                    <div className="option-imgli">
                                        <img src={require(`../assets/images/img/assetManager/option-table4-${lang}.png`)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("OPTION.subtitle4")}</p>
                            <div className="item-content type">
                                <div className="type-item">
                                    <div className="type-title fontMedium"></div>
                                    <div className="type-desc">
                                        <div className="desc fontRegular">
                                            <p>{t("OPTION.content4-title1")}</p>
                                            <p>{t("OPTION.content4-title2")}</p>
                                        </div>
                                        <div className="desc fontRegular">
                                            <p>{t("OPTION.content4-title3")}</p>
                                            <p>{t("OPTION.content4-title4")}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="type-item">
                                    <div className="type-title fontMedium">{t("Bond.content3-title1")}</div>
                                    <div className="type-desc">
                                        <div className="desc fontRegular">
                                            <p>{t("OPTION.content4-desc1")}</p>
                                        </div>
                                        <div className="desc fontRegular">
                                            <p>{t("OPTION.content4-desc3")}</p>
                                        </div>
                                    </div>
                                </div>    
                                <div className="type-item">
                                    <div className="type-title fontMedium">{t("Bond.content3-title2")}</div>
                                    <div className="type-desc">
                                        <div className="desc fontRegular">
                                            <p>{t("OPTION.content4-desc2")}</p>
                                        </div>
                                        <div className="desc">
                                            <p>{t("OPTION.content4-desc4")}</p>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("OPTION.subtitle7")}</p>
                            <div className="item-content">
                                <p className="content-li">{t("OPTION.content7")}</p>
                                <div className="bcwhite">
                                    <p>{t("OPTION.content7-desc1")}</p>
                                </div>
                                <div className="bcwhite">
                                    <p>{t("OPTION.content7-desc2")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("OPTION.subtitle5")}</p>
                            <div className="item-content">
                                <div className="content-item">
                                    <div className="scene-content">
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("OPTION.content5-title5")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("OPTION.content5-desc5")}</p>
                                            </div>
                                        </div>
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("OPTION.content5-title1")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("OPTION.content5-desc1")}</p>
                                            </div>
                                        </div>
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("OPTION.content5-title2")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("OPTION.content5-desc3")}</p>
                                            </div>
                                        </div>
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("OPTION.content5-title3")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("OPTION.content5-desc3")}</p>
                                            </div>
                                        </div>
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("OPTION.content5-title4")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("OPTION.content5-desc4")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("OPTION.subtitle6")}</p>
                            <div className="item-content">
                                <div className="content-item">
                                    <div className="scene-content option-example">
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("OPTION.content6-title1")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("OPTION.content6-desc1-li1")}</p>
                                                <p>{t("OPTION.content6-desc10-li2")}</p>
                                            </div>
                                        </div>
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("OPTION.content6-title2")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("OPTION.content6-desc2-li1")}</p>
                                                <p>{t("OPTION.content6-desc2-li2")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                type === 'Fixed' && <div className="Fixed education">
                    <div className="detail-title">
                        <p>{t("FIXED.title")}</p>
                    </div>
                    <div className="detail-content">
                        <div className="content-item">
                            <div className="item-content">
                                <p className="content-li">{t("FIXED.content1")}</p>
                            </div>
                        </div>
                        {/* <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("FIXED.subtitle2")}</p>
                            <div className="item-content">
                                <div className="content-item">
                                    <div className="scene-content fixedType">
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("FIXED.content2-title1")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("FIXED.content2-desc1")}</p>
                                            </div>
                                        </div>
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("FIXED.content2-title2")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("FIXED.content2-desc2")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("FIXED.content3-title1")}</p>
                            <div className="item-content">
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("FIXED.content3-subtitle1")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content3-desc1")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("FIXED.content3-subtitle2")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content3-desc2")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("FIXED.content3-subtitle3")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content3-desc3")}</p>
                                </div>
                                <p className="item-title fontMedium fontMedium">{t("FIXED.content3-title2")}</p>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("FIXED.content3-subtitle4")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content3-desc4")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("FIXED.content3-subtitle5")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content3-desc5")}</p>
                                </div>
                            </div>
                        </div>
                        {/* <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("FIXED.subtitle4")}</p>
                            <div className="item-content">
                                <div className="content-item">
                                    <div className="scene-content fixedRisk">
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("FIXED.content4-title1")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("FIXED.content4-desc1")}</p>
                                            </div>
                                        </div>
                                        <div className="content-list">
                                            <div className="content-li-title">
                                                <p className="fontMedium">{t("FIXED.content4-title2")}</p>
                                            </div>
                                            <div className="content-li-desc fontRegular">
                                                <p>{t("FIXED.content4-desc2")}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="content-item">
                            <div className="item-content fixedScene">
                                <p className="item-title fontMedium fontMedium">{t("FIXED.content4-title3")}</p>
                                <div className="content-item">
                                    {/* <p className="item-desc fontRegular">{t("FIXED.content4-desc3")}</p> */}
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("FIXED.content4-title4")}</p>
                                    {/* <p className="item-desc fontRegular">{t("FIXED.content4-desc4-li1")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content4-desc4-li2")}</p> */}
                                    <div className="item-img">
                                        <img src={require(`../assets/images/img/assetManager/fixed-img1-${lang}.png`)} />
                                    </div>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("FIXED.content4-title4-2")}</p>
                                    {/* <p className="item-desc fontRegular">{t("FIXED.content4-desc4-li1")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content4-desc4-li2")}</p> */}
                                    <div className="item-img">
                                        <img src={require(`../assets/images/img/assetManager/fixed-img2-${lang}.png`)} />
                                    </div>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("FIXED.content4-title5")}</p>
                                    {/* <p className="item-desc fontRegular">{t("FIXED.content4-desc5-li1")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content4-desc5-li2")}</p> */}
                                    <div className="item-img">
                                        <img src={require(`../assets/images/img/assetManager/fixed-img3-${lang}.png`)} />
                                    </div>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("FIXED.content4-title6")}</p>
                                    {/* <p className="item-desc fontRegular">{t("FIXED.content4-desc6-li1")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content4-desc6-li2")}</p> */}
                                    <div className="item-img">
                                        <img src={require(`../assets/images/img/assetManager/fixed-img4-${lang}.png`)} />
                                    </div>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("FIXED.content4-title7")}</p>
                                    {/* <p className="item-desc fontRegular">{t("FIXED.content4-desc7-li1")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content4-desc7-li2")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content4-desc7-li3")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content4-desc7-li4")}</p> */}
                                    <div className="item-img">
                                        <img src={require(`../assets/images/img/assetManager/fixed-img5-${lang}.png`)} />
                                    </div>
                                </div>
                            </div>
                            {/* <div className="item-content fixedScene">
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("FIXED.content4-title8")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content4-desc8")}</p>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("FIXED.content4-title9")}</p>
                                    <div className="item-img">
                                        <img src={require(`../assets/images/img/assetManager/fixed-img1-${lang}.png`)} />
                                    </div>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("FIXED.content4-title10")}</p>
                                    <div className="item-img">
                                        <img src={require(`../assets/images/img/assetManager/fixed-img2-${lang}.png`)} />
                                    </div>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("FIXED.content4-title11")}</p>
                                    <div className="item-img">
                                        <img src={require(`../assets/images/img/assetManager/fixed-img3-${lang}.png`)} />
                                    </div>
                                </div>
                                <div className="content-item">
                                    <p className="content-title fontMedium">{t("FIXED.content4-title12")}</p>
                                    <div className="item-img">
                                        <img src={require(`../assets/images/img/assetManager/fixed-img4-${lang}.png`)} />
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="content-item">
                            <p className="item-title fontMedium fontMedium">{t("FIXED.subtitle5")}</p>
                            <div className="item-content">
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("FIXED.content5-desc1-title1")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content5-desc1-desc1")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("FIXED.content5-desc1-title2")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content5-desc1-desc2")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("FIXED.content5-desc1-title3")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content5-desc1-desc3")}</p>
                                </div>
                                <div className="content-item content-adv">
                                    <p className="item-title fontMedium fontRegular">{t("FIXED.content5-desc1-title4")}</p>
                                    <p className="item-desc fontRegular">{t("FIXED.content5-desc1-desc4")}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                type === 'Wealth_Inheritance' && <div className="Wealth_Inheritance">
                    <div className="topInfo">
                        <div className="top-name"><p>{t("Wealth.title")}</p></div>
                        <div className="top-desc"><p>{t("Wealth.content1-desc1")}</p></div>
                    </div>
                    <div className="detail-title">
                        <p>{t("Wealth.title")}</p>
                    </div>
                    <div className="detail-content">
                        <div className="detail-desc">
                            <div className="desc-title">
                                <div className="title-text fontBlod">{t("Wealth.subtitle1")}</div>
                            </div>
                            
                            <div className="desc-info">
                                <p className="info-text fontNormal">{t("Wealth.content1-desc1")}</p>
                                <p className="info-text fontNormal">{t("Wealth.content1-desc2")}</p>
                                <p className="info-text fontNormal">{t("Wealth.content1-desc3")}</p>
                                <p className="info-text fontNormal">{t("Wealth.content1-desc4")}</p>
                            </div>
                        </div>
                        {/* <div className="family-Structure">
                            <img src={require(`@/assets/images/img/assetManager/img-familyStructure-${lang}.png`)} />
                        </div>
                        <div className="Family-Advantage">
                            <img src={require(`@/assets/images/img/assetManager/img-FamilyAdvantage-${lang}.png`)} />
                        </div> */}
                        <div className="advantage-content">
                            <div className="desc-title">
                                <div className="title-text fontBlod">{t("Wealth.subtitle2")}</div>
                            </div>
                            <div className="desc-content">
                                <div className="list-box">
                                    {
                                        ASSETMANAGEMENTS.map((e, i) =>
                                            <AssetManagementItem key={i} title={e.title} num={i} type="wealth" content={e.content} ></AssetManagementItem>
                                        )
                                    }
                                </div>
                                {/* {
                                    familyAdvantage.map((e,index)=>(
                                        <div className="list-item" key={index}>
                                            <div className="item-title fontMedium">
                                                <div className="icon"></div>
                                                <div className="text fontMedium">
                                                    {e.title}
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                } */}
                            </div>
                        </div>
                        <img className="divider" src={require("../assets/images/divider.png")} />
                        <div className="amc-content">
                            <div className="content-title fontBlod">{t("Wealth.subtitle3")}</div>
                            <div className="content-desc">
                                <div className="desc-title">
                                    <div className="title-text fontBlod">{t("Wealth.content3-subtitle1")}</div>
                                </div>
                                <div className="desc-text fontRegular">{t("Wealth.content3-desc1-text1")}</div>
                                <div className="desc-text fontRegular">{t("Wealth.content3-desc1-text2")}</div>
                            </div>
                            <div className="content-desc">
                                <div className="desc-title">
                                    <div className="title-text fontBlod">{t("Wealth.content3-subtitle2")}</div>
                                </div>
                                <div className="desc-text fontRegular descFlex">
                                    <img className="icon" src={require("../assets/images/icon/icon-check.png")} />
                                    <p className="text">{t("Wealth.content3-desc2-text1-li1")}<span>{t("Wealth.content3-desc2-text1-li2")}</span>{t("Wealth.content3-desc2-text1-li3")}</p>
                                </div>
                                <div className="desc-text fontRegular descFlex">
                                    <img className="icon" src={require("../assets/images/icon/icon-check.png")} />
                                    <p className="text">{t("Wealth.content3-desc2-text2-li1")}<span>{t("Wealth.content3-desc2-text2-li2")}</span>{t("Wealth.content3-desc2-text2-li3")}</p>
                                </div>
                                <div className="desc-text fontRegular descFlex">
                                    <img className="icon" src={require("../assets/images/icon/icon-check.png")} />
                                    <p className="text">{t("Wealth.content3-desc2-text3-li1")}<span>{t("Wealth.content3-desc2-text3-li2")}</span>{t("Wealth.content3-desc2-text3-li3")}</p>
                                </div>
                                <div className="desc-text fontRegular descFlex">
                                    <img className="icon" src={require("../assets/images/icon/icon-check.png")} />
                                    <p className="text">{t("Wealth.content3-desc2-text4-li1")}<span>{t("Wealth.content3-desc2-text4-li2")}</span>{t("Wealth.content3-desc2-text4-li3")}</p>
                                </div>
                                <div className="desc-text fontRegular descFlex">
                                    <img className="icon" src={require("../assets/images/icon/icon-check.png")} />
                                    <p className="text">{t("Wealth.content3-desc2-text5-li1")}<span>{t("Wealth.content3-desc2-text5-li2")}</span>{t("Wealth.content3-desc2-text5-li3")}</p>
                                </div>
                            </div>
                            <div className="content-desc advantage-desc">
                                <div className="desc-title">
                                    <div className="title-text fontBlod">{t("Wealth.subtitle4")}</div>
                                </div>
                                <div className="desc-item">
                                    <img src={require("../assets/images/img/wealth/img-advantage-tag1.png")} alt="" />
                                    <div className="item-title fontMedium">
                                        <div className="box"></div>
                                        <div className="title-text">{t("Wealth.content4-subtitle1")}</div>
                                    </div>
                                    <div className="item-desc fontRegular">
                                        {t("Wealth.content4-desc1-li1")}<span>{t("Wealth.content4-desc1-li2")}</span>{t("Wealth.content4-desc1-li3")}
                                    </div>
                                </div>
                                <div className="desc-item">
                                    <img src={require("../assets/images/img/wealth/img-advantage-tag2.png")} alt="" />
                                    <div className="item-title fontMedium">
                                        <div className="box"></div>
                                        <div className="title-text">{t("Wealth.content4-subtitle2")}</div>
                                    </div>
                                    <div className="item-desc fontRegular">
                                        {t("Wealth.content4-desc2-li1")}<span>{t("Wealth.content4-desc2-li2")}</span>{t("Wealth.content4-desc2-li3")}
                                    </div>
                                </div>
                                <div className="desc-item">
                                    <img src={require("../assets/images/img/wealth/img-advantage-tag3.png")} alt="" />
                                    <div className="item-title fontMedium">
                                        <div className="box"></div>
                                        <div className="title-text">{t("Wealth.content4-subtitle3")}</div>
                                    </div>
                                    <div className="item-desc fontRegular">
                                        {t("Wealth.content4-desc3-li1")}<span>{t("Wealth.content4-desc3-li2")}</span>{t("Wealth.content4-desc3-li3")}
                                    </div>
                                </div>
                                <div className="desc-item">
                                    <img src={require("../assets/images/img/wealth/img-advantage-tag4.png")} alt="" />
                                    <div className="item-title fontMedium">
                                        <div className="box"></div>
                                        <div className="title-text">{t("Wealth.content4-subtitle4")}</div>
                                    </div>
                                    <div className="item-desc fontRegular">
                                        {t("Wealth.content4-desc4-li1")}<span>{t("Wealth.content4-desc4-li2")}</span>{t("Wealth.content4-desc4-li3")}
                                    </div>
                                </div>
                                <div className="desc-item">
                                    <img src={require("../assets/images/img/wealth/img-advantage-tag5.png")} alt="" />
                                    <div className="item-title fontMedium">
                                        <div className="box"></div>
                                        <div className="title-text">{t("Wealth.content4-subtitle5")}</div>
                                    </div>
                                    <div className="item-desc fontRegular">
                                        {t("Wealth.content4-desc5-li1")}<span>{t("Wealth.content4-desc5-li2")}</span>{t("Wealth.content4-desc5-li3")}
                                    </div>
                                </div>
                                <div className="desc-item">
                                    <img src={require("../assets/images/img/wealth/img-advantage-tag6.png")} alt="" />
                                    <div className="item-title fontMedium">
                                        <div className="box"></div>
                                        <div className="title-text">{t("Wealth.content4-subtitle6")}</div>
                                    </div>
                                    <div className="item-desc fontRegular">
                                        <p className="item-li">{t("Wealth.content4-desc6-li1")}</p>
                                        <p className="item-li">{t("Wealth.content4-desc6-li2")}<span>{t("Wealth.content4-desc6-li3")}</span>{t("Wealth.content4-desc6-li3-1")}</p>
                                        <p className="item-li">{t("Wealth.content4-desc6-li4")}<span>{t("Wealth.content4-desc6-li5")}</span>{t("Wealth.content4-desc6-li6")}</p>
                                        <p className="item-li">{t("Wealth.content4-desc6-li7")}<span>{t("Wealth.content4-desc6-li8")}</span>{t("Wealth.content4-desc6-li9")}</p>
                                        <p className="item-li">{t("Wealth.content4-desc6-li10")}<span>{t("Wealth.content4-desc6-li11")}</span>{t("Wealth.content4-desc6-li12")}</p>
                                        <p className="item-li"> {t("Wealth.content4-desc6-li13")}<span>{t("Wealth.content4-desc6-li14")}</span>{t("Wealth.content4-desc6-li15")}</p>
                                    </div>
                                    
                                </div>
                                <div className="desc-item">
                                    <img src={require("../assets/images/img/wealth/img-advantage-tag7.png")} alt="" />
                                    <div className="item-title fontMedium">
                                        <div className="box"></div>
                                        <div className="title-text">{t("Wealth.content4-subtitle7")}</div>
                                    </div>
                                    <div className="item-desc fontRegular">
                                        {t("Wealth.content4-desc7-li1")}<span>{t("Wealth.content4-desc7-li2")}</span>{t("Wealth.content4-desc7-li3")}
                                    </div>
                                </div>
                                <div className="desc-item">
                                    <img src={require("../assets/images/img/wealth/img-advantage-tag8.png")} alt="" />
                                    <div className="item-title fontMedium">
                                        <div className="box"></div>
                                        <div className="title-text">{t("Wealth.content4-subtitle8")}</div>
                                    </div>
                                    <div className="item-desc fontRegular">
                                        {t("Wealth.content4-desc8-li1")}<span>{t("Wealth.content4-desc8-li2")}</span>{t("Wealth.content4-desc8-li3")}
                                    </div>
                                </div>
                                <div className="content-desc">
                                    <div className="desc-text fontRegular last-desc">{t("Wealth.content5")}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                type === 'Investment_Philosophy' && <div className="Investment_Philosophy">
                    <div className="topInfo">
                        <div className="top-name">{t("InvestmentPhilosophy.title-2")}</div>
                        <div className="top-desc">{t("InvestmentPhilosophy.desc1-2")}</div>
                    </div>
                    <div className="detail-title fontBlod">
                        <p>{t("about.about-investment-title")}</p>
                    </div>
                    <div className="detail-content">
                        <div className="title-box">
                            <div className="content-title fontBlod">{t("InvestmentPhilosophy.title")}</div>
                            <div className="line"></div>
                            <div className="content-desc fontNormal">
                                {t("InvestmentPhilosophy.desc1")}
                            </div>
                        </div>
                        <div className="content-li">
                            <div className="content-title fontBlod">{t("InvestmentPhilosophy.subtitle1")}</div>
                            <div className="content-list basis-list">
                                {
                                    InvestmentPhilosophyList1.map((e,i)=>(
                                        <div className={`list-item ${activeBasicTag === i ? 'active-item' : ''}`} key={i} onClick={()=>addClassName(i)}>
                                            <img src={require(`../assets/images/img/philosophy/img-philosophy-tag${i+1}.png`)} />
                                            <div className="item-info">
                                                <div className="item-title fontMedium">
                                                    <div className="title-text fontMedium">{e.title}{">"}</div>
                                                </div>
                                                <div className="item-desc fontRegular fontRegular">{e.desc}</div>
                                            </div>
                                        </div> 
                                    ))
                                }
                            </div>
                        </div>
                        <div className="content-li">
                            <div className="content-title fontBlod">{t("InvestmentPhilosophy.subtitle2")}</div>
                            <div className="content-list">
                                {
                                    InvestmentPhilosophyList2.map((e,i)=>(
                                        <div className="list-item" key={i}>
                                            <div className="item-title fontMedium">
                                                <div className="title-text fontMedium">{e.title}</div>
                                            </div>
                                            <div className="item-desc fontRegular fontRegular">{e.desc}</div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="flex-box">
                            <div className="content-box">
                                <div className="content-title fontMedium">{t("InvestmentPhilosophy.subtitle3")}</div>
                                <div className="content-desc fontNormal">{t("InvestmentPhilosophy.content3-desc")}</div>
                            </div>
                            <div className="content-box">
                                <div className="content-title fontMedium">{t("InvestmentPhilosophy.subtitle4")}</div>
                                <div className="content-desc fontNormal">{t("InvestmentPhilosophy.content4-desc")}</div>
                            </div>
                            <div className="content-box">
                                <div className="content-title fontMedium">{t("InvestmentPhilosophy.subtitle5")}</div>
                                <div className="content-desc fontNormal">{t("InvestmentPhilosophy.content5-desc")}</div>
                            </div>
                            <div className="content-box">
                                <div className="content-title fontMedium">{t("InvestmentPhilosophy.subtitle6")}</div>
                                <div className="content-desc fontNormal">{t("InvestmentPhilosophy.content6-desc")}</div>
                            </div>
                            <div className="content-box">
                                <div className="content-title fontMedium">{t("InvestmentPhilosophy.subtitle7")}</div>
                                <div className="content-desc fontNormal">{t("InvestmentPhilosophy.content7-desc")}</div>
                            </div>
                            <div className="content-box">
                                <div className="content-title fontMedium">{t("InvestmentPhilosophy.subtitle8")}</div>
                                <div className="content-desc fontNormal">{t("InvestmentPhilosophy.content8-desc")}</div>
                            </div>
                            <div className="content-box">
                                <div className="content-title fontMedium">{t("InvestmentPhilosophy.subtitle9")}</div>
                                <div className="content-desc fontNormal">{t("InvestmentPhilosophy.content9-desc")}</div>
                            </div>
                            <div className="content-box">
                                <div className="content-title fontMedium">{t("InvestmentPhilosophy.subtitle10")}</div>
                                <div className="content-desc fontNormal">{t("InvestmentPhilosophy.content10-desc")}</div>
                            </div>
                            <div className="content-box">
                                <div className="content-title fontMedium">{t("InvestmentPhilosophy.subtitle11")}</div>
                                <div className="content-desc fontNormal">{t("InvestmentPhilosophy.content11-desc")}</div>
                            </div>
                        </div>
                        <div className="content-desc fontNormal">{t("InvestmentPhilosophy.desc2")}</div>
                        <div className="content-desc fontNormal">{t("InvestmentPhilosophy.desc3")}</div>
                    </div>
                </div>
            }
        </div>
    </div>  
}


