import { Divider } from "antd";
import { useTranslation } from "react-i18next";
import { useViewport } from "../hooks/useViewport";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {Dropdown} from "antd"


export default function AppFooter(params) {
    const {t} = useTranslation()
    const {width} = useViewport()
    const navigate = useNavigate()
    const location = useLocation()

    let [codeOpen,setCodeOpen] = useState(false)
    let [headerClass,setHeaderClass] = useState()
    let [codeClick,setCodeClick] = useState(false)

    const items = [
        {
            key: "1",
            label: (
                <div className="wechatCode">
                    <img 
                        src={require("../assets/images/icon/wechatCode.jpg")} 
                        onClick={()=>imgClick()}
                    />
                </div>
            )
        }
    ]

    const aboutItems = [
        {
          key: '1',
          label: (
            <a onClick={()=>linkClick("about","introduction")}>
              {t("header.title1-sub1")}
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a onClick={()=>linkClick("about","values")}>
              {t("header.title1-sub2")}
            </a>
          ),
        },
        {
          key: '3',
          label: (
            <a onClick={()=>linkClick("about","team")}>
              {t("header.title1-sub3")}
            </a>
          ),
        },
        {
          key: '4',
          label: (
            <a onClick={()=>linkClick("about","news")}>
                {t("header.title1-sub4")}
            </a>
          ),
        },
      ];

    const serviceItems = [
        {
          key: '5',
          label: (
            <a onClick={()=>linkClick("services","wealth")}>
              {t("index.services-subtitle5")}
            </a>
          ),
        },
        {
          key: '1',
          label: (
            <a onClick={()=>linkClick("services","Asset_Management")}>
              {t("header.title2-sub1")}
            </a>
          ),
        },
        {
          key: '2',
          label: (
            <a onClick={()=>linkClick("services","Investment_Management")}>
              {t("header.title2-sub2")}
            </a>
          ),
        },
        {
          key: '4',
          label: (
            <a onClick={()=>linkClick("services","Brokerage_Business")}>
              {t("header.title2-sub4")}
            </a>
          ),
        }
    ]

    const insightsItems = [
      {
        key: '1',
        label: (
          <a onClick={()=>linkClick("insights","Special_report")}>
            {t("header.title3-sub1")}
          </a>
        ),
      },
      
      {
        key: '3',
        label: (
          <a onClick={()=>linkClick("insights","Market_review")}>
            {t("header.title3-sub3")}
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <a onClick={()=>linkClick("insights","Research_report")}>
            {t("header.title3-sub2")}
          </a>
        ),
      },
  ]

    const Structured_ProductsItems = [
      {
        key: '1',
        label: (
          <a onClick={()=>linkClick("AMC","TrackingNotes")}>
            {t("AMCPage.subtitle2")}
          </a>
        ),
      },
      {
        key: '2',
        label: (
          <a onClick={()=>linkClick("AMC","TrackingCertificate")}>
            {t("AMCPage.subtitle1")}
          </a>
        ),
      },
    ]

    const handleClick = () => {
        if (!codeClick && codeOpen) {
            setCodeOpen(true)
            setCodeClick(true)
        }else{
            codeOpen = !codeOpen;
            setCodeOpen(codeOpen);
            codeClick = !codeClick;
            setCodeClick(codeClick)
        }
    }

    const linkClick = (type,data) => {
        let oldAnchorPoint = sessionStorage.getItem('anchorPoint');
        if (oldAnchorPoint === data) {
          console.log(1);
          sessionStorage.removeItem('anchorPoint')
          // sessionStorage.setItem('anchorPoint','')
          sessionStorage.setItem('anchorPoint',data)
        }else{
          sessionStorage.setItem('anchorPoint',data)
        }
        window.dispatchEvent(new Event("setanch"))
        let path = location.pathname.split('/')[1];
        if (path !== type) {
          navigate(`/${type}`)
        } 
      }
  
      const handleScroll = () => {
        let header = document.getElementsByClassName('Header');
        if ( window.pageYOffset >= 100 ) {
            header[0].classList.add('Header-fix');
            headerClass = 'Header-fix';
            setHeaderClass(headerClass)
        }
        if ( window.pageYOffset === 0 ) {
            header[0].classList.remove('Header-fix');
            headerClass = '';
            setHeaderClass(headerClass)
        }
      }

    const handleMouseEnter = () => {
        if (!codeClick) {
            setCodeOpen(true)
        }
    }

    const handleMouseLeave = () => {
        if (!codeClick) {
            setCodeOpen(false)
        }
    }
    
    const imgClick = () => {
        setCodeOpen(false);
        setCodeClick(false)
    }

    useEffect(()=>{
        console.log("click===>",codeClick);
    },[codeClick])

    useEffect(()=>{
        console.log("codeOpen====>",codeOpen);
    },[codeOpen])

    return (
        <div className={`container ${width < 765 && 'containerMobile'} ${width >= 766 && width <= 1366 && 'containerIpad'}`}>
            <div className="content">
                {/* <p className="title fontBlod">Build To Last</p> */}
                <div className="footer-logo">
                    <img src={require('../assets/images/img/footer-logo.png')} />
                </div>
                <div className="footer-content">
                    <div className="content-item">
                        <p className="item-title">{t("header.title1")}</p>
                        <ul>
                            {
                                aboutItems.map((e,i)=>(
                                    <li key={i}>
                                        {e.label}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="content-item">
                        <p className="item-title">{t("header.title2")}</p>
                        <ul>
                            {
                                serviceItems.map((e,i)=>(
                                    <li key={i}>
                                        {e.label}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="content-item">
                        <p className="item-title">{t("header.title3")}</p>
                        <ul>
                            {
                                insightsItems.map((e,i)=>(
                                    <li key={i}>
                                        {e.label}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="content-item">
                        <p className="item-title">{t("header.title7")}</p>
                        <ul>
                            <li>
                                <a onClick={()=>navigate('details?Personal_Data_Policy')}>{t("footer.title3")}</a>
                            </li>
                            <li>
                                <a onClick={()=>navigate('details?Disclaimer')}>{t("footer.title4")}</a>
                            </li>
                        </ul>
                    </div>
                    <div className="subtitle content-item">
                        <p className="item-title">{t("footer.title1")}</p>
                        <a target="_blank" href="https://www.linkedin.com/company/btl-asset-management-limited/"><img src={require("@/assets/images/icon/icon-in.png")} alt="" /><span>linkedin</span></a>
                        <Dropdown
                            menu={{
                                items
                            }}
                            arrow
                            placement="top"
                            open={codeOpen}
                            getPopupContainer={triggerNode => triggerNode.parentNode}
                        >
                            <a 
                                className="wechat"
                                onMouseEnter={()=>handleMouseEnter()}
                                onMouseLeave={()=>handleMouseLeave()}
                                onClick={()=>handleClick()}
                            >
                                <img src={require("@/assets/images/icon/wechat.png")} alt="" />
                                <span>微信公众号</span>
                            </a>
                        </Dropdown>
                    </div>
                </div>
                <p className="copyright">©2023-BTL Asset Management Company Ltd</p>
            </div>
        </div>
    )
}