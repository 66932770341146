export default function AreaCoverageItem(props) {
    const { content } = props;

    return (
        <div className="AreaCoverageItem">
            <div className="icon">
                {/* <div></div> */}
                <img src={require('../../../assets/images/icon/icon-circle.png')} />
            </div>
            <p className="title">{content}</p>
        </div>
    )
}