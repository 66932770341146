import { useEffect, useState } from "react"

export default function Map(props) {

    const { width, height } = props

    let [lang,setLang] = useState(localStorage.getItem("btl.lang"))
    let [dataLang,setDataLang] = useState()

    useEffect(()=>{
        console.log(lang);
        if (lang === 'zh-TW') {
            dataLang = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3691.4183014327054!2d114.1766143!3d22.3000145!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ef633d3155%3A0xf011bc37cfd203d4!2z6I-v5oeL5buj5aC0!5e0!3m2!1szh-TW!2s!4v1696921055746!5m2!1szh-TW!2s';
            setDataLang(dataLang)
        }else if (lang === 'en-US') {
            dataLang = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d922.8545995604892!2d114.17797147600197!3d22.300010836260952!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ef633d3155%3A0xf011bc37cfd203d4!2sChinachem%20Golden%20Plaza!5e0!3m2!1sen!2s!4v1696921966514!5m2!1sen!2s';
            setDataLang(dataLang)
        }else{
            dataLang = 'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d922.8545998000255!2d114.1779715!3d22.3000108!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x340400ef633d3155%3A0xf011bc37cfd203d4!2z5Y2O5oeL5bm_5Zy6!5e0!3m2!1szh-CN!2s!4v1696922414853!5m2!1szh-CN!2s';
            setDataLang(dataLang);
        }
    },[lang])

    useEffect(()=>{
        window.addEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
        return window.removeEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
    },[])
    
    return <iframe
        src={dataLang}
        width={width}
        height={height} 
        style={{border: "none"}}
        loading="lazy" 
    ></iframe>
}