export default function WealthItem(props) {
    const { img, title, content } = props;

    return (
        <div className="WealthItem">
            <img className="item-icon" src={img} alt=""></img>
            <div className="background"></div>
            <p className="item-title about-subtitle fontBlod">{title}</p>
            <p className="item-content about-content">{content}</p>
        </div>
    )
}