import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function AssetManagementItem(props) {
    const { title, content, url, num, type } = props;
    const {t} = useTranslation()

    const navigateTo = useNavigate();

    const toIndex = () => {
        navigateTo(url)
    }

    return (
        <div className="AssetManagementItem">
            <div className="num-img">
                <img src={require(`../../../assets/images/img/pc_num${num+1}.png`)} />
            </div>
            <p className="service-subtitle fontMedium">{title}</p>
            {
                type === 'wealth' && <p className="wealth-content">{content}</p>
            }
            <div className="sub-line"></div>
            {/* <p className="desc service-content">{content}</p> */}
            <p className="link service-content">
                {
                    url && <a className="read-more" href={`/details?${url}`} >{t("others.learn")}<img src={require('../../../assets/images/img/img-right.png')} /></a>
                }
                
            </p>
        </div>
    )
}


