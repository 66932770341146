import { useEffect } from "react";

export default function ProductItem(props) {
    
    const { img, title, content, lang, dataLang, setModalOpen, setCheckProduct, id } = props;
    const statement = localStorage.getItem('agreeStatement');

    const open = () => {
        if (statement) {
            setCheckProduct(id);
            setModalOpen(true)
        }
    }

    return (
        <div className="ProductItem" onClick={()=>open()}>
            <div className="item-header">
                <div className="img">
                    <img src={img} alt="" />
                </div>
                <p className="index-subtitle fontMedium">{title[dataLang]}</p>
                <div className="line"></div>
            </div>
            <div className="item-content">
                <p className="index-content">{content[dataLang]}</p>
            </div>
        </div>
    )
}