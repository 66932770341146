import "@/assets/styles/component-style/news.scss"
import { useEffect,useState } from "react";


export default function CustomNews(props) {
    
    const { title, type, content, img, date } = props;

    let [lang,setLang] = useState(localStorage.getItem("btl.lang"))
    let [dataLang,setDataLang] = useState()

    useEffect(()=>{
        console.log("content-----",title);
    },[content])

    useEffect(()=>{
        if (lang === 'zh-TW') {
            dataLang = 'hk';
            setDataLang(dataLang)
        }else if (lang === 'zh-CN') {
            dataLang = 'cn';
            setDataLang(dataLang);
        }else{
            dataLang = 'en';
            setDataLang(dataLang)
        }
    },[lang])

    useEffect(()=>{
        window.addEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
        return window.removeEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
    },[])
    
    return (
        <div className="CustomNews">
            {
                type === "news" ?
                <div className="item-banner">
                    <img src={img} alt="" style={{width: "100%", height: "100%"}} />
                </div>
                :
                <div className="item-banner servers-banner">
                    {title&&title[dataLang]}
                </div>
            }
            <div className="item-content">
                <p className={`title ${type === "news" ? "" : "mb93"}`}>{title&&title[dataLang]}</p>
                {
                    type === "news" &&
                    <p className="date content">{date}</p>
                }
                <div className="txt content" dangerouslySetInnerHTML={{__html: content&&content[dataLang]}}>
                </div>
            </div>
        </div>
    )
}