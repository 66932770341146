import { useTranslation } from "react-i18next";
import "@/assets/styles/view-style/index.scss"
import "@/assets/styles/component-style/indexItem.scss"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Carousel, Dropdown, Modal, Select } from "antd";
import i18n from "i18next";
import { useEffect, useState } from "react";
import CustomMap from "@/components/CustomMap";
import ProductItem from "../components/CustomItem/index/ProductItem";
import ServiceItem from "../components/CustomItem/index/ServiceItem";
import InsightItem from "../components/CustomItem/index/InsightItem";
import EducationItem from "../components/CustomItem/index/EducationItem";
import { selectZoom } from "../utils/detectZoom";
import { getBannerList, getResearch, getProduct } from "../request/api";
import { listProcessing, allListProcessing } from "../utils/dataProcessing";
import { useViewport } from "../hooks/useViewport";



export default function Index(params) {
    
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate()
    const {width} = useViewport()


    const cacheLang = localStorage.getItem('btl.lang');
    const statementOpen = localStorage.getItem('agreeStatement')

    let [lang,setLang] = useState(cacheLang ? cacheLang : navigator.language)
    let [dataLang,setDataLang] = useState()

    let [bannerItem, getBannerItem] = useState([])
    let [bannerList,setBannerList] = useState([])
    let [researchList,setResearchList] = useState([])
    let [productList,setProductList] = useState([])
    let [noBanner,setNoBanner] = useState(false)
    let [modalOpen,setModalOpen] = useState(false)
    let [checkProduct,setCheckProduct] = useState();
    let [showProduct,setShowProduct] = useState({});
    let [showTitle,setShowTitle] = useState({});
    let [showDesc,setShowDesc] = useState({})
    let [statementModal,setStatementModal] = useState(!statementOpen ? true : false)
    let [modalLang,setModalLang] = useState(cacheLang ? cacheLang : navigator.language)


    const SERVICES = [
        {
            img: require(`@/assets/images/img/img-service-cc.png`),
            title: t("index.services-subtitle5"),
            content: t("index.services-desc5")
        },
        {
            img: require(`@/assets/images/img${width < 481 ? '/mobile' : ''}/img-service-lc${width < 481 ?'-large' : ''}.png`),
            title: t("index.services-subtitle1"),
            content: t("index.services-desc1")
        },
        {
            img: require(`@/assets/images/img${width < 481 ? '/mobile' : ''}/img-service-tz${width < 481 ?'-large' : ''}.png`),
            title: t("index.services-subtitle2"),
            content: t("index.services-desc2")
        },
        {
            img: require(`@/assets/images/img${width < 481 ? '/mobile' : ''}/img-service-jj${width < 481 ?'-large' : ''}.png`),
            title: t("index.services-subtitle3"),
            content: t("index.services-desc3")
        },
        {
            img: require(`@/assets/images/img${width < 481 ? '/mobile' : ''}/img-service-yw${width < 481 ?'-large' : ''}.png`),
            title: t("index.services-subtitle4"),
            content: t("index.services-desc4")
        },
        {
            img: require(`@/assets/images/img/img-service-comingsoon.png`),
            title: t("index.services-subtitle6")
        }
    ]

    const INSIGHT = [
        {
            img: require("@/assets/images/img/img-insight-1.png"),
            title: t("MUTUAL_FUND.title"),
            content: t("MUTUAL_FUND.content1"),
            link: "/details?MUTUAL_FUND"
        },
        {
            img: require("@/assets/images/img/img-insight-2.png"),
            title: t("Bond.title"),
            content: t("Bond.content1"),
            link: "/details?Bond"
        },
        {
            img: require("@/assets/images/img/img-insight-3.png"),
            title: t("OPTION.title"),
            content: t("OPTION.content1"),
            link: "/details?OPTION"
        },
        {
            img: require("@/assets/images/img/img-insight-4.png"),
            title: t("FIXED.title"),
            content: t("FIXED.content1"),
            link: "/details?Fixed"
        },
    ]

    const modalLangList = [
        {
            value: 'en-US',
            label: (
                <div onClick={()=>langChange('en-US')}>English</div>
            )
        },
        {
            value: 'zh-TW',
            label: (
                <div onClick={()=>langChange('zh-TW')}>繁體中文</div>
            )
        },
        {
            value: 'zh-CN',
            label: (
                <div onClick={()=>langChange('zh-CN')}>简体中文</div>
            )
        },
    ]

    const langChange = (val) => {
        localStorage.setItem("btl.lang", val)
        window.dispatchEvent(new Event("storage"));
        i18n.changeLanguage(val)
        setModalLang(val);
      }    

      useEffect(()=>{
        console.log("modal---->",modalLang);
      },[modalLang])

    const getBanner = async () => {
        await getBannerList()
        .then((res) => {
            if ( res.code === 0 ) {
                if (!res.data.list) {
                    setNoBanner(true)
                    let header = document.getElementsByClassName('Header');
                    header[0].classList.add('Header-noBanner');
                    return
                }
                let arr = allListProcessing('banner',res.data.list)
                bannerList = arr;
                setBannerList([...bannerList])
            }
        }).catch((err) => {
            console.log(err);
        });
        bannerItem = [1,2,3]
        getBannerItem([...bannerItem]);
    }

    const getResearchList = async () => {
        await getResearch({page: 1})
        .then((res) => {
            if ( res.code === 0 ) {
                let arr = [];
                let list = res.data.list
                list.map((e,i)=>{
                    let titleObj = {};
                    let descObj = {};
                    if (arr.length < 4) {
                        e.data.map((ele,ind)=>{
                            if ( ele.language === 'zh_CN' ) {
                                titleObj.cn = ele.title;
                                descObj.cn = ele.description;
                            } else if (ele.language === 'zh_HK') {
                                titleObj.hk = ele.title;
                                descObj.hk = ele.description;
                            }else {
                                titleObj.en = ele.title;
                                descObj.en = ele.description
                            }
                        })
                        arr.push({
                            title: titleObj,
                            desc: descObj,
                            file: e.file,
                            permission: e.permission,
                            img: e.image
                        })
                    }
                })
                researchList = arr;
                setResearchList([...researchList])
            }
        }).catch((err) => {
            console.log(err);
        });
    }

    const getProduction = async () => {
        await getProduct()
        .then((res) => {
            let arr = listProcessing('product',res.data.list);
            productList = arr;
            setProductList([...productList])
        }).catch((err) => {
            
        });
    }

    const agreeStatement = () => {
        localStorage.setItem('agreeStatement',true)
        setStatementModal(false)
    }

    const init = () => {
        getBanner()
        getResearchList()
        getProduction()
    }

    const bannerHandle = (e) => {
        if (e.indexOf('http') > -1) {
            window.location.href = e
        }else{
            navigate(e)
        }
    }

    const scrollToAnchor = (anchorName) => {
        let anchorElement = document.getElementsByClassName(`Index-${anchorName}`)[0];
        anchorElement.scrollIntoView({ behavior: 'smooth', block:'start' })
    }

    const modalCancel = () => {
        setModalOpen(false)
    }

    useEffect(() => {
        if (location.search !== "") {
            setTimeout(() => {
                scrollToAnchor(location.search.split("?")[1]);
            }, 20);
        }
    },[location])
    

    useEffect(()=>{
        if (lang === 'zh-TW') {
            dataLang = 'hk';
            setDataLang(dataLang)
        }else if (lang === 'zh-CN') {
            dataLang = 'cn';
            setDataLang(dataLang);
        }else{
            dataLang = 'en';
            setDataLang(dataLang)
        }
    },[lang])

    useEffect(()=>{
        window.addEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
        return window.removeEventListener('storage',()=>{
            lang = localStorage.getItem('btl.lang');
            setLang(lang)
        })
    },[])

    useEffect(() => {
        init()
        window.onbeforeunload = function(){
            //刷新后页面自动回到顶部
          document.documentElement.scrollTop = 0;  //ie下
          document.body.scrollTop = 0;  //非ie
        }
    },[])

    useEffect(()=>{
        if (checkProduct) {
            productList.map((e)=>{
                if (e.id === checkProduct) {
                    showProduct = e;
                    setShowProduct({...showProduct})
                    showTitle = e.title
                    setShowTitle({...showTitle});
                    showDesc = e.desc;
                    setShowDesc({...showDesc})
                }
            })
        }
    },[checkProduct])

    // useEffect(()=>{
    //     if (!statementOpen) {
    //         setStatementModal(true)
    //     }
    // },[location])


    return (
        <div className={`Index ${width < 765 && 'pageMobile IndexMobile'} ${width >= 766 && width <= 1366 && 'IndexIpad'} `}>
            {/* 轮播图 */}
            <Carousel className="Index-banner" autoplay>
                {
                    !noBanner ?
                    bannerList.map((e,i) => 
                        <div key={i} className="banner-item" onClick={()=>bannerHandle(e.link)}>
                            <div className="img" >
                                <img src={e.image} />
                            </div>
                            <div className="banner-label">
                                <p className="banner-title">{e.title[dataLang]}</p>
                                <p className="banner-title">{e.desc[dataLang]}</p>
                            </div>
                        </div>
                    )
                    :
                    <div className="banner-item no-banner">
                        <div className="img">
                            <img src={require('@/assets/images/bg/bannerEmpty.png')} />
                        </div>
                    </div>
                }
            </Carousel>


            {/* 关于 */}
            <div className="Index-about index-item">
                <div className="content m-auto">
                    <p className="index-title fontMedium">{t("index.us-title")}</p>
                    <div className="index-content">
                        <p>{t("index.us-desc1")}</p>
                        <p>{t("index.us-desc2")}</p>
                    </div>
                </div>
            </div>
                {/* 产品 */}
                <div className="Index-product index-item">
                    <div className="content m-auto">
                        {
                            productList.map((e,i) => 
                                <ProductItem 
                                    key={i} 
                                    img={e.image} 
                                    title={e.title} 
                                    content={e.desc} 
                                    dataLang={dataLang} 
                                    lang={lang} 
                                    id={e.id}
                                    setModalOpen={setModalOpen}
                                    setCheckProduct={setCheckProduct}
                                />
                            )
                        }
                    </div>
                </div>
            <div className="layout-content ">


                {/* 服务 */}
                <div className="Index-services index-item">
                    <p className="index-title title fontMedium">{t("index.services-title")}</p>
                    <div className="line" />
                    <div className="content m-auto">
                        {
                            SERVICES.map((e,i) => 
                                <ServiceItem key={i} img={e.img} title={e.title} content={e.content} />
                            )
                        }
                    </div>
                </div>

                {/* 洞察力 */}
                <div className="Index-insight index-item">
                    <p className="index-title title fontMedium">{t("index.insight-title")}</p>
                    <div className="line" />
                    <p className="view-all">
                        <a href="list?Research_report">{t("others.viewall")}<span>{'>>'}</span></a>
                    </p>
                    {
                        researchList.length > 0 && <div className="content m-auto">
                            {
                                researchList.map((e,i) => 
                                    <InsightItem key={i} file={e.file} img={e.img} title={e.title} bg={e.image} desc={e.desc} dataLang={dataLang} />
                                )
                            }
                            {
                                researchList.length === 1 || researchList.length === 3 ? <div className={`InsightItem report-empty-item ${width < 765 && 'report-empty-mobile'}`}>
                                    <p>{t("others.comming-soon")}</p>
                                </div> : ''
                            }
                        </div>
                    }
                </div>

                {/* 教育 */}
                <div className="Index-education index-item">
                    <p className="index-title title fontMedium">{t("index.investor-title")}</p>
                    <div className="line" />
                    <div className="content m-auto">
                        {
                            INSIGHT.map((e,i) => 
                                <EducationItem key={i} img={e.img} title={e.title} content={e.content} link={e.link} />
                            )
                        }
                    </div>
                </div>

            </div>
            {/* 地图 */}
            <div className="Index-contact index-item">
                <div className="absolute">
                    <p className="index-title title fontMedium">{t("index.contact-title")}</p>
                    <div className="line" />
                    <div className="content m-auto">
                        <CustomMap Mapwidth={600} height={515} />
                    </div>
                </div>
            </div>
            <Modal className={`product-modal ${width < 766 && 'product-modalMobile'} ${width > 765 && width < 1367 && 'product-modalIpad'}`}
                open={modalOpen}
                onCancel={modalCancel}
                footer={false}
            >
                <div className="product">
                    <div className="icon">
                        <img src={showProduct.image} />
                    </div>
                    <div className="title">
                        <p>{showTitle[dataLang]}</p>
                    </div>
                    <div className="line"></div>
                    <div className="content">
                        <p>{showDesc[dataLang]}</p>
                    </div>
                </div>
            </Modal>
            {/* <Modal
                open={statementModal}
                className="statementModal"
                footer={false}
            >
                <div className="statement-content">
                    <div className="header">
                        <div className="header-title">{t("indexModal.title")}</div>
                        <div className="header-img">
                            <img className="lang-icon" src={require(`../assets/images/icon/icon-modal-lang-${modalLang}.png`)} />
                            <Dropdown
                                menu={{
                                    items: modalLangList
                                }}
                                placement="bottom"
                                
                            >
                                <img className="pull-icon" src={require(`../assets/images/icon/icon-modal-lang-pull.png`)} />
                            </Dropdown>
                        </div>
                    </div>
                    <div className="contents">
                        <p className="content-text">{t("indexModal.content1")}</p>
                        <p className="content-text">{t("indexModal.content2")}</p>
                        <p className="content-text">{t("indexModal.content3")}</p>
                    </div>
                    <div className="footer">
                        <div className="Decline" onClick={()=>setStatementModal(false)}>Decline</div>
                        <div className="Accept" onClick={()=>agreeStatement()}>Accept</div>
                    </div>
                </div>
            </Modal> */}
        </div>
    )
}