import serviceAxios from "../index";

const token = localStorage.getItem('TOKEN')

// 获取首页banner
export const getBannerList = () => {

    return serviceAxios({
        url: "banner/list?page=1&pageSize=10",
        method: 'get'
    })
}

// 获取全部市场评论
export const getMarketReview = (data) => {
    const {page} = data
    return serviceAxios({
        url: `productCommentary/list?page=${page}&pageSize=10`,
        method: 'get',
        header: {
            'x-token': token
        }
    })
}

// 获取最新消息news
export const getNews = (data) => {
    const {page} = data
    return serviceAxios({
        url: `news/list?page=${page}&pageSize=10`,
        method: 'get'
    })
}

// 获取研究报告列表
export const getResearch = (data) => {
    const {page} = data
    return serviceAxios({
        url:`researchReport/list?page=${page}&pageSize=10`,
        method: 'get'
    })
}

// 获取特别报告列表
export const getSpecial = (data) => {
    const {page} = data
    return serviceAxios({
        url: `specialEdition/list?page=${page}&pageSize=10`,
        method: 'get',
        headers: {
            'x-token': token
        }
    })
}

// 获取产品推荐列表
export const getProduct = (data) => {

    return serviceAxios({
        url: `production/list?page=1&pageSize=10`,
        method: 'get',
        headers: {
            'x-token': token
        }
    })
}

// 获取AMC列表
export const getAMC = (data) => {
    const { type, page } = data;
    return serviceAxios({
        url: `amc/list?page=${page}&pageSize=10&type=${type}`,
        method: 'get',
        headers: {
            'x-token': token
        }
    })
}


// 登录
export const goLogin = (data) => {

    return serviceAxios({
        url: "user/login",
        method: "post",
        data
    })
}

