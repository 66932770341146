import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function InvestmentManagementItem(props) {
    const { title, content, url } = props;
    const {t} = useTranslation()

    const navigateTo = useNavigate();

    const toIndex = () => {
        navigateTo(url)
    }

    return (
        <div className="InvestmentManagementItem">
            <p className="title service-subtitle fontBlod">{title}</p>
            <div className="content-box">
                {
                    content.map((e, i) =>
                        <p key={i} className="desc service-content fontRegular">{e}</p>
                    )
                }
            </div>
            <p className="link service-content">
                <a className="read-more" href={`/details?${url}`} style={{color: '#F6CEBE'}}>{t("others.learn")}</a>
            </p>
        </div>
    )
}