import { useNavigate } from "react-router-dom";

export default function NewsItem(props) {
    const { img, title, desc, time, id, dataLang } = props;
    const navigate = useNavigate()

    const clickHandle = () => {
        navigate(`/news/${id}?1`)
    }

    return (
        <div className="NewsItem">
            <div className="img-box">
                <img src={img} alt="" onClick={clickHandle} ></img>
            </div>
            <div className="news-container">
                <p className="news-title about-subtitle fontMedium" onClick={clickHandle}>{title[dataLang]}</p>
                <p className="news-content about-content" onClick={clickHandle}>{desc[dataLang]}</p>
                <p className="news-time about-content">{time}</p>
            </div>
        </div>
    )
}