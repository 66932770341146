import "@/assets/styles/view-style/list.scss"
import { Divider, message, Pagination } from "antd"
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom'
import { getMarketReview, getResearch, getSpecial, getNews, getAMC } from "../request/api";
import { allListProcessing } from '@/utils/dataProcessing'
import { useTranslation } from "react-i18next";
import { useViewport } from "../hooks/useViewport";

export default function List () {

    const location = useLocation()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {width} = useViewport()

    let [list,setList] = useState([])
    let [type,setType] = useState()
    let [total,setTotal] = useState()
    let [page,setPage] = useState(1)
    let [title,setTitle] = useState();
    let [lang,setLang] = useState(localStorage.getItem('btl.lang'));
    let [dataLang,setDataLang] = useState()

    const getList = async () => {
        if(type && type==='Research_report'){
            await getResearch({
                page
            })
            .then((res) => {
                console.log('getList-----',res);
                if (res.code===0){
                    let data = allListProcessing(type,res.data.list)
                    total = res.data.total
                    setTotal(total)
                    console.log("data---------",data);
                    list = data;
                    setList([...list])
                }
            }).catch((err) => {
                // message.error('获取数据失败')
            });
        }else if(type && type==='Product_view'){
            await getMarketReview({
                page
            })
            .then((res) => {
                if (res.code===0){
                    console.log("data---",res);
                    let data = allListProcessing(type,res.data.list)
                    total = res.data.total
                    setTotal(total)
                    list = data;
                    setList([...list])
                }else{

                }
            }).catch((err) => {

            });
        }else if(type && type==='Special_report'){
            await getSpecial({
                page
            })
            .then((res) => {
                if (res.code===0){
                    let data = allListProcessing(type,res.data.list);
                    console.log('data====',data);
                    total = res.data.total
                    setTotal(total)
                    list = data;
                    setList([...list])
                }
            })
        }else if (type && type === 'news'){
            await getNews({
                page
            })
            .then((res) => {
                let data = allListProcessing(type,res.data.list)
                console.log("news----",data);
                total = res.data.total;
                setTotal(total);
                list = data;
                setList([...list])
            })
        }else if (type && type === 'AWGOI') {
            await getAMC({
                page,
                type: 1
            })
            .then((res) => {
                if (res.code===0){
                    let data = allListProcessing(type,res.data.list);
                    console.log('data====',data);
                    total = res.data.total
                    setTotal(total)
                    list = data;
                    setList([...list])
                }
            }).catch((err) => {
                
            });
        }else if (type && type === "BAOI") {
            await getAMC({
                page,
                type: 2
            })
            .then((res) => {
                if (res.code===0){
                    let data = allListProcessing(type,res.data.list);
                    console.log('data====',data);
                    total = res.data.total
                    setTotal(total)
                    list = data;
                    setList([...list])
                }
            }).catch((err) => {
                
            });
        }
    }

    const toNews = (id) => {
        console.log("id=====",id);
        if (type === 'news') {
            navigate(`/news/${id}?${page}`)
        }
    }

    const showList = () => {
        return (
            list.length > 0 && list.map((e,i)=>(
                <div className="list-item" key={i}>
                    <div className="img" onClick={()=>toNews(e.id)}>
                        <img src={e.image} />
                    </div>
                    <div className="list-text">
                        <h5 className={`text-title ${type === 'news' && 'news-title'}`} onClick={()=>toNews(e.id)}  >{e.title[dataLang]}</h5>
                        <p className="text-desc">{e.desc[dataLang]}</p>
                        {
                            type === 'news' ?
                            <span className="learn-more" onClick={()=>toNews(e.id)} >{t("others.learn")}</span> 
                             : 
                            <a href={e.file} target='_blank' className="text-down">{t("others.download")}</a>
                        }
                    </div>
                </div>
            ))
        )
    }


    useEffect(()=>{
        console.log('location-----',location);
        if (location.search){
            type = location.search.split('?')[1]
            setType(type)
            if (type === 'Research_report') {
                title = 'header.title3-sub2'
            }else if (type === 'Special_report') {
                title = 'header.title3-sub1'
            }else if (type==='Product_view'){
                title = 'header.title3-sub3'
            }else if (type==='AWGOI') {
                title = 'AMCPage.subtitle1'
            }else if (type==='BAOI') {
                title = 'AMCPage.subtitle2'
            }
            else{
                title = 'header.title1-sub4'
            }
            
            setTitle(title) 
        }
    },[location])

    useEffect(()=>{
        getList()
    },[type])

    useEffect(()=>{
        if (lang === 'zh-TW') {
            dataLang = 'hk';
            setDataLang(dataLang)
        }else if (lang === 'zh-CN') {
            dataLang = 'cn';
            setDataLang(dataLang);
        }else{
            dataLang = 'en';
            setDataLang(dataLang)
        }
    },[lang])

    useEffect(()=>{
        lang = localStorage.getItem('btl.lang');
        setLang(lang)
    },[localStorage.getItem('btl.lang')])

    useEffect(()=>{
        getList()
    },[page])

    useEffect(()=>{
        console.log("total===",total);
    },[total])

    return <div className={
            `list-page ${width <= 765 && 'list-page-mobile'} 
            ${width >= 766 && width <= 1366 ? 'list-page-ipad' : ''}
            ${width >= 766 && width <= 1366 && type === 'Research_report' && 'market-ipad'}
            ${width >= 766 && width <= 1366 && type === 'Product_view' && 'market-ipad'}
            ${width >= 766 && width <= 1366 && type === 'Special_report' && 'specialR-ipad'}
        `}>
        <div className="list-banner">
            <h4 className="fontMedium">{t(`${title}`)}</h4>
            {/* {
                type === 'news' ? '' : <p>{t("Insights.desc")}</p>
            } */}
        </div>
        <div className="layout-content">
            <div className="list-content">
                {/* <Divider 
                    orientation="left" 
                >
                </Divider> */}
                <div className="content-title">
                    <span className="list-title">{t(`${title}`)}</span>
                </div>
                <div className="content-list content-list-expect">
                    {showList()}
                </div>
                {
                    !total ? '' : <Pagination current={page} total={total} onChange={(val)=>setPage(val)} />
                }
            </div>
        </div>
    </div>
}

