import { Link, useLocation, useRoutes } from "react-router-dom";
import { Layout } from "antd";
import AppHeader from "./containers/AppHeader";
import AppFooter from "./containers/AppFooter";
import "@/assets/styles/containers.scss"
import routes from "@/router";
import { detectZoom } from "@/utils/detectZoom";
import { useEffect, useState } from "react";
import i18n from 'i18next';
import { useViewport } from "./hooks/useViewport";
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs';
const { Header, Footer, Content } = Layout;
const m = detectZoom();
const frontStyle = {
  fontFamily: "PingFang SC"
}

function App() {

  const outlet = useRoutes(routes);
  const location = useLocation('');
  const {width} = useViewport()
  const statement = localStorage.getItem('agreeStatement')

  const baseSize = 16;

  let [statementModal,setStatementModal] = useState(!statement ? true : false)


  // let [language,setLanguage] = useState('zh-TW')

  if (window.screen.width * window.devicePixelRatio >=(1920 * 2)) {
    switch (m) {
      // 4k ==> 100%
      case 100:
        document.body.style.zoom = 100 / (0.625 * 2560);
        break;
        // 4k ==> 125%
      case 125:
        document.body.style.zoom = 100 / (0.625 * 2560);
        break;
        // 4k ==> 150%
      case 150:
        document.body.style.zoom = 100 / (0.75 * 2560);
        break;
        // 4k ==> 175%
      case 175:
        document.body.style.zoom = 100 / (0.874715 * 2560);
        break;
        // 4k ==> 200%
      case 200:
        document.body.style.zoom = 1920 / 2560;
        break;
        // 4k ==> 225%
      case 225:
        document.body.style.zoom = 100 / (1.12485 * 2560);
        break;
    
      default:
        break;
    }
}
else if(m === 100 && window.screen.width === 1920 && window.devicePixelRatio === 1){
    
  document.body.style.zoom = 1920 / 2560;
}
else if (window.screen.width <= 1921 && window.screen.width >= 1440) {
  document.body.style.zoom = 1440 / 1920;
}else{
  console.log("m===>",m);

  // document.body.style.zoom = 1920 / (window.screen.width * window.devicePixelRatio)
    // document.body.style.zoom = 2560 / 1920;
    // console.log();
}

  const handleScroll = () => {
    let header = document.getElementsByClassName('Header');
    if ( window.pageYOffset >= 100 ) {
        header[0].classList.add('Header-fix');
    }
    if ( window.pageYOffset === 0 ) {
        header[0].classList.remove('Header-fix');
    }
  }

  const setRemUnit = () => {
      const clientWidth = document.documentElement.clientWidth;
      if (clientWidth < 764) {
        const scale = clientWidth / 375;
        document.documentElement.style.fontSize = baseSize * Math.min(scale,4)+'px'
        getRem(2560, 100)
        console.log(1);

      }else if (clientWidth > 765 && clientWidth < 1367) {
        const scale = clientWidth / 2560;
        document.documentElement.style.fontSize = 100 * scale + 'px'
        getRem(2560,100)
        console.log(2);
      }else{
        getRem(2560, 100)
        console.log(3);
      }
  }

  // window.onload = function () {
  //   /*720代表设计师给的设计稿的宽度，你的设计稿是多少，就写多少;100代表换算比例，这里写100是
  //     为了以后好算,比如，你测量的一个宽度是100px,就可以写为1rem,以及1px=0.01rem等等*/
  //   getRem(2560, 100)
  // };
  window.onresize = function () {
      getRem(2560, 100)
  };

  function getRem(pwidth, prem) {
      var html = document.getElementsByTagName("html")[0];
      var oWidth = document.body.clientWidth || document.documentElement.clientWidth;
      document.documentElement.style.fontSize = oWidth / pwidth * prem + "px";
  }


  useEffect(() => {
    setRemUnit(2560,100);
    window.addEventListener('resize', setRemUnit(2560,100));

    return () => {
      window.removeEventListener('resize', setRemUnit(2560,100));
    };
  }, []);

  // useEffect(()=>{
  //   getRem();
  //   window.addEventListener('resize',getRem);
  //   return () => {
  //     window.removeEventListener('resize',getRem)
  //   }
  // },[])

  useEffect(()=>{
    window.addEventListener('scroll',handleScroll,true)
  },[])

  useEffect(() => {
    window.scrollTo(0,0)
    console.log("location====",location);
  },[location])

  useEffect(() => {
    // if (location) {
    //   lang = location.hash.split("#")[1];
    //   if (!lang) {
    //     lang = "en_US"
    //   }
    //   setLang(lang);
    // }
    const cacheLang = localStorage.getItem('btl.lang');
    if (!cacheLang) {      
      let lang;
      console.log("navigator===",navigator);
      if (navigator.language !== 'zh-CN' && navigator.language !== 'zh-TW') {
        lang = 'en-US';
      }else{
        lang = navigator.language;
      }
      // // TODO: init test un zh-CN
      // lang = 'en-US'
      localStorage.setItem('btl.lang',lang)
      i18n.changeLanguage(lang)
    }else{
      i18n.changeLanguage(cacheLang)
    }
  },[])

  // useEffect(()=>{
  //   i18n.changeLanguage(language)
  // },[language])

  const test = (
    width < 1366 ?
    {
      hashPriority: "high"
    }
    :
    {}
    // <StyleProvider >
    // </StyleProvider>
  )

  return (
    <StyleProvider {...test} transformers={[legacyLogicalPropertiesTransformer]} >
      <div className="App">
        <Layout>
          <Header className="Header" style={frontStyle} statementModal={statementModal} setStatementModal={setStatementModal}  >
            <AppHeader />
          </Header>
          <Content style={frontStyle} className={`${width < 765 && 'contentMobile'} ${width >= 766 && width <= 1366 && 'contentIpad'}`} >
            { outlet }
          </Content>
          <Footer className={`Footer ${width < 765 && 'FooterMobile'} ${width >= 766 && width <= 1366 && 'FooterIpad'}`} style={frontStyle}>
            <AppFooter />
          </Footer>
        </Layout>
      </div>
    </StyleProvider>
  );
}

export default App;
