

export const listProcessing = (type,list) => {
    let arr = [];
    if (type==='banner'){
        
    }else if (type === 'news'){
        list.map((e,i)=>{
            let titleObj = {};
            let descObj = {};
            if (arr.length < 4) {
                e.news.map((ele,ind)=>{
                    if ( ele.language === 'zh_CN' ) {
                        titleObj.cn = ele.title;
                        descObj.cn = ele.description;
                    } else if (ele.language === 'zh_HK') {
                        titleObj.hk = ele.title;
                        descObj.hk = ele.description;
                    }else {
                        titleObj.en = ele.title;
                        descObj.en = ele.description
                    }
                })
                let time = new Date(e.createdAt*1000)
                let year = time.getFullYear();
                let month = time.getMonth()+1 < 10 ? '0' + (time.getMonth()+1) : time.getMonth()+1;
                let day = time.getDay() < 10 ? '0' + time.getDay() : time.getDay()
                arr.push({
                    id: e.id,
                    title: titleObj,
                    desc: descObj,
                    permission: e.permission,
                    image:e.image,
                    date: year + '-' + month + '-' + day
                })
            }
        })
    }
    else{
        list.map((e,i)=>{
            let titleObj = {};
            let descObj = {};
            if (arr.length < 4) {
                e.data.map((ele,ind)=>{
                    if ( ele.language === 'zh_CN' ) {
                        titleObj.cn = ele.title;
                        descObj.cn = ele.description;
                    } else if (ele.language === 'zh_HK') {
                        titleObj.hk = ele.title;
                        descObj.hk = ele.description;
                    }else {
                        titleObj.en = ele.title;
                        descObj.en = ele.description
                    }
                })
                arr.push({
                    id: e.id,
                    title: titleObj,
                    desc: descObj,
                    permission: e.permission,
                    file: e.file,
                    image:e.image
                })
            }
        })
    } 
    return arr
}

export const allListProcessing = (type,list) => {
    let arr = [];
    if (type==='news'){
        list.map((e,i)=>{
            let titleObj = {};
            let descObj = {};
            let contentObj = {}
            e.news.map((ele,ind)=>{
                if ( ele.language === 'zh_CN' ) {
                    titleObj.cn = ele.title;
                    descObj.cn = ele.description;
                    contentObj.cn = ele.content
                } else if (ele.language === 'zh_HK') {
                    titleObj.hk = ele.title;
                    descObj.hk = ele.description;
                    contentObj.hk = ele.content
                }else {
                    titleObj.en = ele.title;
                    descObj.en = ele.description
                    contentObj.en = ele.content
                }
            })
            arr.push({
                id: e.id,
                title: titleObj,
                desc: descObj,
                content: contentObj,
                permission: e.permission,
                image:e.image
            })
        })
    }else if (type==='banner'){
        list.map((e,i)=>{
            let titleObj = {};
            let descObj = {};
            e.banner.map((ele,ind)=>{
                if ( ele.language === 'zh_CN' ) {
                    titleObj.cn = ele.title;
                    descObj.cn = ele.description;
                } else if (ele.language === 'zh_HK') {
                    titleObj.hk = ele.title;
                    descObj.hk = ele.description;
                }else {
                    titleObj.en = ele.title;
                    descObj.en = ele.description
                }
            })
            arr.push({
                id: e.id,
                title: titleObj,
                desc: descObj,
                permission: e.permission,
                file: e.file,
                image:e.image,
                link: e.link
            })
        })
    }else{
        list.map((e,i)=>{
            let titleObj = {};
            let descObj = {};
            e.data.map((ele,ind)=>{
                if ( ele.language === 'zh_CN' ) {
                    titleObj.cn = ele.title;
                    descObj.cn = ele.description;
                } else if (ele.language === 'zh_HK') {
                    titleObj.hk = ele.title;
                    descObj.hk = ele.description;
                }else {
                    titleObj.en = ele.title;
                    descObj.en = ele.description
                }
            })
            arr.push({
                id: e.id,
                title: titleObj,
                desc: descObj,
                permission: e.permission,
                file: e.file,
                image:e.image
            })
        })
    }
    return arr
}

export const singleProcessing = (type,item) => {
    let obj = {}
    if (type === 'news') {
        let titleObj = {};
        let descObj = {};
        let contentObj = {}
        item.news.map((e,i)=>{
            if ( e.language === 'zh_CN' ) {
                titleObj.cn = e.title;
                descObj.cn = e.description;
                contentObj.cn = e.content
            } else if (e.language === 'zh_HK') {
                titleObj.hk = e.title;
                descObj.hk = e.description;
                contentObj.hk = e.content
            }else {
                titleObj.en = e.title;
                descObj.en = e.description
                contentObj.en = e.content
            }
        })
        let time = new Date(item.createdAt*1000)
        let year = time.getFullYear();
        let month = time.getMonth()+1 < 10 ? '0' + (time.getMonth()+1) : time.getMonth()+1;
        let day = time.getDay() < 10 ? '0' + time.getDay() : time.getDay()
        obj = {
            id: item.id,
            title: titleObj,
            desc: descObj,
            content: contentObj,
            permission: item.permission,
            image:item.image,
            date: year + '-' + month + '-' + day
        }
    }
    return obj
}

