export default function TeamItem(props) {
  const { icon, name, job, desc } = props;

  return (
    <div className="TeamItem">
      <div className="about-personal-info">
          <img style={{pointerEvents: 'none'}} className="circle" src={icon} alt=""></img>
          <div className="about-personal-job">
              <p className="name about-subtitle">{name}</p>
              <div className="sub-line"></div>
              <p className="job about-content">{job}</p>
          </div>
      </div>
      <div className="about-desc-group">
          {
              desc.map((e, i) => <p className="about-desc" key={i}>{e}</p>)
          }
      </div>
      <img className="arrow" src={require("@/assets/images/icon/icon-arrow.png")} alt=""></img>
    </div>
  );
}
